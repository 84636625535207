import { detailsCategoriesConst } from "./constants";
import { launchManualProximityRequest } from "../../../core/manageProximity";
import { getUrlParameter } from '../../../core/manageUrl';
import { addSlider } from "../../slider";
import { openFullBoard, closeFullBoard } from "../../board";
import { displayBoardWithPoi } from "../../board/boardPoi";
import { removeClassCategoriesBtnExceptActive } from "..";
import { categories_const } from "../constants";
import { closeBoardReActiveCategory } from "..";
import SimpleBar from "pnp_core_bundle/simplebar/simplebar";

export function initDetailsCategories() {
    JKisio(document).on('click', detailsCategoriesConst.titlesContainerClass, (event) => {
        tabChangeCategoriesDetails(event);
    });

    JKisio(document).on('click', ".proximity_filters_all_select input", (event) => {
        toggleSelectAll(event);
    });

    JKisio(document).on('click', ".proximity_filters_contents_container", (event) => {
        selectAllUpdate(event);
    });

    JKisio(document).on('keydown', '.proximity_filters_contents_container .proximity_filters_select', (event) => {
        if (['Enter', 'NumpadEnter'].includes(event.key)) {
            event.target.checked = !event.target.checked;
        }
    })

    JKisio(document).on('click', `${detailsCategoriesConst.categoryContainer} ${detailsCategoriesConst.categoryValidateButton}`, (event) => {
        validateClick();
    });

    JKisio(document).on('click', "#ctp-proximity-filters .title_container .ikisio-close", () => {
        closeFilterBoard();
        removeClassCategoriesBtnExceptActive();
    });

    JKisio(document).on('click', '#ctp-proximity-filters .proximity_filters_reset', () => {
        resetFilters();
    })
}

export const openFilterBoard = () => {
    openFullBoard();
    JKisio("#ctp-proximity-filters").show();
    JKisio("#ctp-proximity-stoparea").hide();

    addSlider(detailsCategoriesConst.titlesContainerClass, detailsCategoriesConst.titleSliderContainerClass);

    window.addEventListener("resize", () => scrollBarFilters());

    scrollBarFilters();
}

const closeFilterBoard = () => {
    closeFullBoard();
    JKisio('#ctp-proximity-filters').hide();

    JKisio('#board_container').addClass('board_open_proximity');
    JKisio('#board').addClass('expanded_proximity');
    JKisio('#ctp-proximity').show();

    JKisio('#ctp-proxi-more').removeClass('active_category');

    closeBoardReActiveCategory();

    window.removeEventListener("resize", scrollBarFilters());
}

const tabChangeCategoriesDetails = (event) => {
    let target = event.target;

    if (target.classList.contains(detailsCategoriesConst.titlesContainer)) {
        return
    } else if (target.localName == "img" || target.localName == 'p') {
        target = event.target.parentElement;
    }

    document.querySelector(detailsCategoriesConst.titleActive).classList.remove(detailsCategoriesConst.titleActiveClassName);
    
    target.classList.add(detailsCategoriesConst.titleActiveClassName);

    document.querySelector(".proximity_filters_all_select input").checked = true;
    document.querySelectorAll(".proximity_filters_contents[aria-label='"+target.getAttribute("data-controls")+"'] .proximity_filters_select input").forEach(el => {
        if (el.checked == false) {
            document.querySelector(".proximity_filters_all_select input").checked = false;
        }
    })

    document.querySelector(".proximity_filters_contents.active_content").classList.remove('active_content');
    document.querySelector(".proximity_filters_contents[aria-label='"+target.getAttribute("data-controls")+"']").classList.add('active_content');
    scrollBarFilters();
}

const toggleSelectAll = (event) => {
    let target = event.target;

    let attr = document.querySelector(detailsCategoriesConst.titleActive).getAttribute("data-controls");

    document.querySelectorAll(".proximity_filters_contents[aria-label='"+attr+"'] .proximity_filters_select input").forEach(el => {
        el.checked = target.checked;
    });
}

const selectAllUpdate = (event) => {
    let inputs = document.querySelectorAll('.proximity_filters_contents_container .active_content input');
    let selectAllInput = document.querySelector('.proximity_filters_all_select input');

    let toBeChecked = true;

    inputs.forEach(input => {
        if (input.checked == false) {
            toBeChecked = false;
            return;
        }
    })

    selectAllInput.checked = toBeChecked;
}

const validateClick = () => {
    let queryString = decodeURIComponent(window.location.search);
    let distance = Kisio.map_config.max_poi_search;

    // get Filter selected
    let filters = [];
    const activeCategory = document.querySelector(detailsCategoriesConst.titleActive);
    let stopsTypeEnable = !activeCategory.dataset?.stopsType;
    let attr = activeCategory.getAttribute("data-controls");

    document.querySelectorAll(".proximity_filters_contents[aria-label='"+attr+"'] .proximity_filters_select input:checked").forEach(el => {
        let elId = el.value;
        if (activeCategory.dataset?.stopsType && el.dataset?.filterType === "stops") {
            stopsTypeEnable = true;
        }
        filters.push(elId);
    });

    let queryShowStops = stopsTypeEnable ? getUrlParameter(queryString, 'proximity_search[show_stops]') : "false";
    let autocompleteHidden = getUrlParameter(queryString, 'proximity_search[uri][autocomplete-hidden]');
    let autocomplete= getUrlParameter(queryString, 'proximity_search[uri][autocomplete]');
    let coords = getUrlParameter(queryString, 'proximity_search[coords]');

    let result = launchManualProximityRequest(
        autocompleteHidden,
        autocomplete,
        coords,
        distance,
        filters,
        queryShowStops
    );

    displayBoardWithPoi(result, attr, document.querySelector(detailsCategoriesConst.titleActive +" img").src, true);
    
    // add data-active to element active
    // used for keeping the info of the element active when detailCategories is open
    let newActiveEl = document.querySelector(`${categories_const.categoriesClass}[data-name="${attr}"]`);
    let oldActive = document.querySelector(`${categories_const.categoriesClass}[data-active="true"]`);
    oldActive.dataset.active = 'false';
    newActiveEl.dataset.active = 'true';
}

const resetFilters = () => {
    document.querySelectorAll(".proximity_filters_contents .proximity_filters_select input").forEach(el => {
        el.checked = true;
    });

    document.querySelector('.proximity_filters_all_select input').checked = true;
}

const scrollBarFilters = () => {
    let filtersContainer = document.querySelector('.proximity_filters_contents.active_content');

    const windowHeight = window.innerHeight;
    const containerHeight = filtersContainer.scrollHeight;
    const containerOffsetTop = filtersContainer.getBoundingClientRect().top;

    if ((containerHeight + containerOffsetTop) > windowHeight) {
        filtersContainer.style.maxHeight = `${windowHeight - containerOffsetTop - 50}px`;
        new SimpleBar(filtersContainer);
    }else if (filtersContainer.style.maxHeight != "100%"){
        filtersContainer.style.maxHeight = "100%";
        window.requestAnimationFrame(waitForStyleToBeApplied);
    }
}

const waitForStyleToBeApplied = () => {
    let filtersContainer = document.querySelector('.proximity_filters_contents.active_content');
    const computedStyle = window.getComputedStyle(filtersContainer);
    if (computedStyle.maxHeight === "100%") {
        scrollBarFilters();
    } else {
        window.requestAnimationFrame(waitForStyleToBeApplied);
    }
};