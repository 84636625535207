/**
 * Function to determine which Manager (cookies or Hofund) is enabled
 * @returns {string}
 */
export const determineFavManager = function () {
    let favorites_manager;
    if (Kisio.enable_fav_cookies !== undefined && Kisio.enable_fav_cookies) {
        favorites_manager = 'cookies';
    }
    if (Kisio.enable_fav_hofund !== undefined && Kisio.enable_fav_hofund) {
        favorites_manager = 'hofund';
    }
    return favorites_manager;
}

/**
 * Function to test in JS if the Management of Favorites is enabled for this type and which Manager (Cookies or Hofund) is defined
 * @returns {[boolean, string]}
 */
export const enableFavorites = function (type) {
    let display_favorites = false;
    let favorites_manager, favManager;
    if (Kisio.enable_favorites) {
        // We enable the favorites only if this particular type is authorized te be used
        if (Kisio.favorites_types.indexOf(type) > -1) {
            // We enable the favorites only if one Manager is enabled also
            favManager = determineFavManager();
            if (favManager !== undefined) {
                display_favorites = true;
                favorites_manager = favManager;
            }
        }
    }
    return [display_favorites, favorites_manager];
};

/**
 * Function to check Favorites on page and change their respective status accordingly
 * @param favorites_manager
 */
export const checkFavoritesOnPage = function (favorites_manager, elt){
    //retrieve the parameters of the current URL
    let parameters = getQueryParameter(window.location.search);
    let listFavorites;
    if (parameters.is_journey_schedules !== undefined) {
        // When coming from a favorite route, hide all the stars in the LDS and FDR
        Kisio('.fav-star').hide();
    } else {
        if (elt === undefined) {
            elt = '';
        }
        listFavorites = null;
        // We grab the list of Favorites depending on the manager
        if (favorites_manager === 'cookies' && Kisio.user_consent) {
            listFavorites = read_local_storage_item('favorites');
        } else if (favorites_manager === 'hofund') {
            //Request to get bookmarks if they exist
            Kisio.ajax({
                url: Routing.generate('hofund_get_user_bookmarks', {type_product: (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : ''}),
                async: false,
                success: function (result) {
                    listFavorites = result;
                }
            });
        }
        if (listFavorites !== null) {
            // We circle on the various Type of Favorites
            for (let favoriteType in listFavorites) {
                let favoriteTypeObject = listFavorites[favoriteType];
                let favoriteTypeFavList = favoriteTypeObject.list
                // We circle on the various favorites and update their status
                for (const favorite of favoriteTypeFavList) {
                    // We check the case if we needed to store the variable encoded
                    let favToFilled = Kisio(elt + '.fav-star.fav-star-add[data-favorites-params="'+encodeURIComponent(favorite)+'"]');
                    // If no correspondence is found, we check the case if we needed to store the variable NOT encoded
                    if (favToFilled.length === 0) {
                        favToFilled = Kisio(elt + '.fav-star.fav-star-add[data-favorites-params="'+favorite+'"]');
                    }
                    // If either way produce a result, we update the status to added of the FavStar corresponding
                    if (favToFilled.length > 0) {
                        favToFilled = favToFilled[0];
                        updateFavStarStatus(favToFilled, 'added', false);
                    }
                }
            }
        }
    }
};

export var hideProxFavContent = function (div, type, callback) {
    let height = Kisio(div).height();
    if (type === 'proximity') {
        Kisio('#ctp-proximityForm .autocomplete-search-icon, #ctp-proximityForm .locate-position').hide();
    }

    Kisio(div).hide();
    if (type === 'proximity') {
        Kisio('#proximity_content').html('');
    }
    Kisio('#board_title_container, #board_footer').show();
    if (Kisio('#schedule_title').hasClass('selected')) {
        Kisio('#schedule_content').show();
    }else{
        if (Kisio('#result_content').length > 0 && !Kisio('#result_content').is(':visible')) {
            Kisio('#result_content').show();
        }
    }
    if (type === 'favorite') {
        Kisio('#favorites_content').remove();
    }
    if (typeof callback === "function") {
        callback();
    }
};