import { hideProximity } from "../../core/index"
import { closeBoardReActiveCategory, removeClassCategoriesBtnExceptActive } from "../categories";
import { openFilterBoard } from "../categories/detailsCategories";
import { categories_const } from "../categories/constants";
import { disableCategoriesBtnExceptActive } from "../categories";
import { eventToggleProximityContainer, eventHoverBoardToMap } from "./boardPoi";

export const openFullBoard = () => {
    hideProximity();
    
    JKisio("#ctp-proximity-filter").show();
    JKisio("#board_container").addClass("board_open");
    JKisio("#board").addClass("expanded");

    JKisio("#ctp-proximity").hide();

    JKisio('#proximity-results').addClass("proximity-results-marge");

    eventCloseBoard();
}

export const closeFullBoard = () => {
    JKisio('#ctp-proximity-filter').hide();
    JKisio('#board_container').removeClass('board_open');
    JKisio('#board').removeClass('expanded');
    JKisio('#proximity-results').removeClass("proximity-results-marge");

    JKisio('#ctp-proximity').show();
    JKisio('#board_container').addClass('board_open_proximity');
    JKisio('#board').addClass("expanded_proximity");
    window.leaflet.map.closePopup();

    let history = document.querySelector('#board-history');
    if (history != null) {
        history.innerHTML = "";
    } 
}

const eventCloseBoard = () => {
    document.querySelector("#ctp-proximity-stoparea .ikisio-close").addEventListener("click", closeBoard);
    document.querySelector("#ctp-proximity-stoparea .ikisio-close").addEventListener("keydown", (event) => {
        if (['Enter', 'NumpadEnter'].includes(event.key)) {
            closeBoard();
        }
    });
}

const closeBoard = () => {
    closeFullBoard();
    JKisio("#ctp-proximity-stoparea").hide();
    JKisio('#legend-ctp-proxi-more').removeClass('active_category');
    removeClassCategoriesBtnExceptActive();
    closeBoardReActiveCategory();
    document.querySelector("#ctp-proximity-stoparea .ikisio-close").removeEventListener("click", closeBoard);
    document.querySelector("#ctp-proximity-stoparea .ikisio-close").removeEventListener("keydown", closeBoard);
}

export const eventListenerBack = () => {
    let returnElement = document.querySelector(".proximity_stoparea_container .back");
    if (returnElement == null) {
        return;
    }

    returnElement.addEventListener("click", (e) => {
        window.leaflet.map.closePopup();
        
        let history = document.querySelector('#board-history');
        
        if (returnElement.getAttribute('data-type') == 'single' || returnElement.getAttribute('data-type') == 'stop-point') {
            if (history) {
                if (history.children.length > 0) {
                    document.querySelector('#proximity_stoparea_content').innerHTML = history.innerHTML;
                    eventListenerBack();
                    eventToggleProximityContainer();
                    eventHoverBoardToMap();
                    return;
                }
            }
        }

        if (history != null) {
            history.innerHTML = "";
        } 

        openFilterBoard();
        
        let allFilterElement = document.querySelector("#ctp-proxi-more");
        if (allFilterElement.classList.contains("active_category") == false) {
            let categoriesElements = document.querySelectorAll(`${categories_const.categoriesClass} .category_unchecked`);

            [...categoriesElements].forEach((el) => {
                el.classList.remove("active_category");
            })

            allFilterElement.classList.add("active_category");
            disableCategoriesBtnExceptActive();
        }
    });
}

export const isFullBoardOpen = () => {
    return (JKisio("#board_container").hasClass('board_open') && JKisio("#board").hasClass('expanded'));
}