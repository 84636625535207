import { board_const } from "../../core/constants";
import { categories_const } from "./constants";
import { addSlider, resizeSlider } from "../slider";
import { launchManualProximityRequest } from "../../core/manageProximity";
import { displayBoardWithPoi } from "../board/boardPoi";
import {getUrlParameter} from "../../core/manageUrl";
import { openFilterBoard } from "./detailsCategories";

export function poiFilterContainerDisplay() {
    if (!JKisio('.board_open_proximity')) { return; }
    let board = document.querySelector(board_const.boardContainer);
    let parentContainer = document.querySelector(board_const.parentContainer);
    let margin = board.offsetParent.offsetWidth * 0.05 + 60;
    let maxWidth = board.offsetParent.offsetWidth - ( margin + parentContainer.offsetLeft + parentContainer.offsetWidth )
    let poiBlock = document.querySelector(categories_const.poiBlock);
    
    if (poiBlock == null) {
        return;
    }
    
    let contentWidth = poiBlock.offsetWidth;

    if (window.innerWidth < 850) {
        maxWidth = board.offsetWidth;
    }

    let element = document.querySelector(categories_const.proximityResult)
    element.style.width = maxWidth + 'px';
    let attributeWidthName = 'initWidth'
    if(!element.hasAttribute(attributeWidthName)){
        element.setAttribute(attributeWidthName, maxWidth);
    }
    
    if (maxWidth < contentWidth) {
        addSlider(
            '#ctp-poi-bloc',
            '.proximity-results-container'
        );
    }

    let elementSlider = document.querySelector(categories_const.poiBlock);
    let parentSlider = document.querySelector(categories_const.proximityResultContainer);

    if (element.getAttribute('windowResize') == null) {
        element.setAttribute('windowResize', true) ;
        window.addEventListener("resize", () => {
            if (JKisio('.board_open_proximity')) {
                poiFilterContainerDisplay();
                resizeSlider(
                    elementSlider,
                    parentSlider,
                    board,
                    parentContainer,
                    margin,
                    maxWidth
                );
            }
        });
    }
}

export function onClickCategory(event, element) {
    event.preventDefault();
    if (element.classList.contains('disable_category')) {
        return;
    }

    let categoriesElements = document.querySelectorAll(`${categories_const.categoriesClass}.category_unchecked`);

    [...categoriesElements].forEach((el) => {
        el.classList.remove("active_category");
    })

    element.classList.add("active_category");

    if (element.getAttribute('id') == 'ctp-proxi-more') {
        disableCategoriesBtnExceptActive();
    }

    let categoryName = element.querySelector("legend").textContent;
    let categoryImg = element.querySelector("legend img").src;
    
    window.leaflet.map.closePopup();

    // Refresh POI exept if id = ctp-proxi-more
    if (element.getAttribute('id') == "ctp-proxi-more") {
        openFilterBoard();
        return;
    }

    let oldActive = document.querySelector(`${categories_const.categoriesClass}[data-active="true"]`);
    oldActive.dataset.active = 'false';
    // add data-active to element active
    // used for keeping the info of the element active when detailCategories is open
    element.dataset.active = "true";

    let distance = Kisio.map_config.max_poi_search;
    let filters = [];

    let filtersDataset = JSON.parse(element.dataset.filters);
    const queryString = decodeURIComponent(window.location.search);
    const showStops = getUrlParameter(queryString, 'proximity_search[show_stops]');

    filters = Object.keys(filtersDataset);

    let autocompleteHidden = JKisio('#proximity_search_uri_autocomplete-hidden').val();
    let autocomplete = JKisio('#proximity_search_uri_autocomplete').val();
    let coords = JKisio('#proximity_search_uri_autocomplete').attr('data-coord');

    let result = launchManualProximityRequest(
        autocompleteHidden,
        autocomplete,
        coords,
        distance,
        filters,
        showStops
    );

    displayBoardWithPoi(result, categoryName, categoryImg);
};

export const disableCategoriesBtnExceptActive = () => {
    let categoriesBtn = document.querySelectorAll(categories_const.categoriesClass + ' legend');
    
    categoriesBtn.forEach((el) => {
        if (!el.classList.contains('active_category')) {
            el.classList.add('disable_category');
        }
    });
}

export const removeClassCategoriesBtnExceptActive = () => {
    let categoriesBtn = document.querySelectorAll(categories_const.categoriesClass + ' legend');
    
    categoriesBtn.forEach((el) => {
        el.classList.remove('disable_category');
    });
}

export const getActiveCategoryFilter = () => {
    let filters = [];
    
    const queryString = decodeURIComponent(window.location.search);
    filters = getUrlParameter(queryString, 'filter');

    if (filters.length > 0) {
        return filters;
    }

    let element = document.querySelector(`${categories_const.categoriesClass} .active_category`);  

    let filtersDataset = JSON.parse(element.dataset.filters);

    filters = Object.keys(filtersDataset);

    return filters;
}

export const closeBoardReActiveCategory = () => {
    let el = document.querySelector(`${categories_const.categoriesClass}[data-active="true"]`);
    el.classList.add('active_category');
}