export function addSlider(
    elementClass,
    divParentClass,
) {
    let element = document.querySelector(elementClass);
    let parentEl = document.querySelector(divParentClass); 

    if (element.offsetWidth <= parentEl.offsetWidth) {
        return;
    }

    if (parentEl.querySelector('.slider-right') && parentEl.querySelector('.slider-left')) {
        return
    }

    const sliderRight = document.createElement('div');
    sliderRight.className = "slider-right slider";
    sliderRight.addEventListener("click", () => moveRightPoiSlider(
        element,
        parentEl
    ) );
    parentEl.appendChild(sliderRight);

    const sliderLeft = document.createElement('div');
    sliderLeft.className = "slider-left slider";
    sliderLeft.setAttribute("style", "display:none");
    sliderLeft.addEventListener("click", () => moveLeftPoiSlider(
        element,
        parentEl
    ) );
    parentEl.appendChild(sliderLeft);
}

export function resizeSlider(
    element,
    parent,
    board,
    parentContainer,
    margin,
    maxWidth
) {
    let currentTransform = parseInt(parent.style.transform.replace(/[^\d.]/g, ''));
    element.style.transform = "translateX(-"+(currentTransform + (parent.getAttribute('initWidth') - (board.offsetParent.offsetWidth - ( margin + parentContainer.offsetLeft + parentContainer.offsetWidth ))) )+"px)"

    let maxTranslate = maxWidth + currentTransform;
    let elements = element.children;
    for (let index = 0; index < elements.length; index++) {
        if ((elements[index].offsetLeft + elements[index].offsetWidth) >= maxTranslate) {
            parent.querySelector('.slider-right').display = flex;
        }
    }

    let translateValue = parseInt(parent.style.transform.replace(/[^\d.]/g, ''));

    for (let index = (elements.length-1); index >= 0; index--) {
        if ((elements[index].offsetLeft ) <= (translateValue)) {
                parent.querySelector('.slider-left').display = flex;
        }
    }
}

function moveRightPoiSlider(
    element,
    parentEl
) {
    let contentWidth = element.offsetWidth;
    let maxWidth = parentEl.offsetWidth;
    let elements = element.children;
    let translate = 0;

    let translateValue = parseInt(element.style.transform.replace(/[^\d.]/g, ''));

    let maxTranslate = parentEl.offsetWidth;

    if (translateValue > -1) {
        maxTranslate = maxWidth + translateValue;
    }

    if (isNaN(translateValue)) {
        translateValue = 0;
    }

    for (let index = 0; index < elements.length; index++) {
        if ((elements[index].offsetLeft + elements[index].offsetWidth) >= (translateValue + maxWidth))  { 
            if (index == elements.length - 1) {
                parentEl.querySelector('.slider-right').style.display = "none";
                translate = "translateX(-"+((contentWidth-maxWidth))+"px)";
                break;
            }

            translate = "translateX(-"+(translateValue + elements[index].offsetWidth)+"px)";
            break;
        }else if (index == elements.length -1){
            translate = "translateX(-"+(contentWidth-maxWidth)+"px)";
            parentEl.querySelector('.slider-right').style.display = "none";
            break;
        }
    }

    element.style.transform = translate;
    parentEl.querySelector('.slider-left').style.display = "flex";
}


function moveLeftPoiSlider(
    element,
    parentEl
) {
    let maxWidth = parentEl.offsetWidth;

    let elements = element.children;
    let translate = 0;

    let translateValue = parseInt(element.style.transform.replace(/[^\d.]/g, ''));

    let maxTranslate = maxWidth;
    if (translateValue > -1) {
        maxTranslate = maxWidth + translateValue;
    }

    for (let index = (elements.length-1); index >= 0; index--) {
        if ((elements[index].offsetLeft ) <= (translateValue)) {
            if (index == 0) {
                parentEl.querySelector('.slider-left').style.display = "none";
                translate = "translateX(0px)";
            break;
            }

            translate = "translateX(-"+(translateValue - elements[index].offsetWidth)+"px)";
            break;
        }
    }
    element.style.transform = translate;

    parentEl.querySelector('.slider-right').style.display = "flex";
}