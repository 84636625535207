import { openFullBoard, closeFullBoard, isFullBoardOpen, eventListenerBack } from "../";
import { createStopPointPopUp } from "../../../core/managePopUp";
import { displayLines } from "../../../core/drawStopPointsLines";
import { getModePicto, getLinePicto } from 'pnp_core_bundle/plugnplay/customize_client';
import { board_stoppoint_const } from "./constants";
import { BookmarkStarElementBuilder } from "bookmark_bundle/components/bookmark-star/bookmark-star-element";
import { NavitiaBuilder } from "bookmark_bundle/builder/navitia/navitia-builder";
import SimpleBar from "pnp_core_bundle/simplebar/simplebar";

export const displayStopPointPopUp = (markerStop) => {
    const stop = JKisio(markerStop).data('stop-object');

    document.querySelector("#ctp-proximity-stoparea .ikisio-back")?.addEventListener("click", () => {
        closeBoardStopPoint();
    });

    JKisio.ajax({
        url: Routing.generate('r_public_transport_objects_filtered',
            {
                type_product: (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : '',
                public_transport_object_type: 'stop_points',
                public_transport_object_id: stop.stop_point.id,
                action: 'routes',
                count: 100
            }),
        dataType: 'json',
        success: function (result) {
            let lines = [];
            let listModes = [];
            result.routes.forEach(function (r) {
                let line = r.line;
                
                let lineIndex = lines.findIndex((x) => x.id == line.id);
                if (lineIndex == -1) {
                    line['routes'] = [r];
                    line['directions'] = [{
                        direction: r.direction,
                        routes: [r.id]
                    }]
                    lines.push(line);
                }else {
                    lines[lineIndex]['routes'].push(r);
                    let routeDirectionIndex = lines[lineIndex]['directions'].findIndex((x) => x.direction.id == r.direction.id);
                    if ( routeDirectionIndex == -1) {
                        lines[lineIndex]['directions'].push({
                            direction: r.direction,
                            routes: [r.id]
                        });
                    }else {
                        lines[lineIndex]['directions'][routeDirectionIndex].routes.push(r.id);
                    }
                }

                let mode = r.line.commercial_mode;
                if (listModes.findIndex((x) => x.id == r.line.commercial_mode.id) == -1) {
                    listModes.push(mode);
                }
            });
            
            createStopPointPopUp(markerStop, stop, result, listModes, lines);

            displayLines(listModes, lines);

            if (isFullBoardOpen()) {
                openBoardStopPoint(markerStop, listModes, lines, true);
            }else {
                openBoardStopPoint(markerStop, listModes, lines);
            }
        },
        error: function (xhr, status, error) {
            console.log(error);
        }
    });
}

const openBoardStopPoint = (
    markerStop,
    listModes,
    lines,
    withHistory = false
) => {
    let stop = JKisio(markerStop).data('stop-object');

    openFullBoard();
    JKisio("#ctp-proximity-stoparea").show();
    JKisio("#ctp-proximity-filters").hide();

    let contentHeadHtml = generateContentHeadHtml(listModes);

    let contentHtml = generateContentHtml(listModes, lines, stop);

    let html = `<div class="proximity_stoparea_container">`;
    
    if (withHistory) {
        html +=    `<div class="back" data-type="stop-point"><em class="ikisio back-arrow"></em> ${Translator.trans('proximity.form.back')}</div>`
    }
    
    html +=        `<div class="proximity_stoparea_title">${stop.name}</div>
                    <div class="proximity_stoparea_content">
                        <div class="proximity_stoparea_mode_container">
                            ${contentHeadHtml}
                        </div>
                        ${contentHtml}
                    </div>
                </div>`;


    let div = document.createElement('div');
    div.innerHTML = html;

    document.querySelector("#proximity_stoparea_content").innerHTML = html;

    document.querySelector('.proximity_stoparea_mode_container').addEventListener("click", (event) => {
        let element = event.target.closest(".proximity_stoparea_mode");
        if (element !== null) {
            addEventListenerListModes(element);
        }
    });

    if (withHistory == true) {
        eventListenerBack(html);
    }

    lines.forEach(function (l) {
        getNextDeparturesLineForStopPoint(l, stop.id);
    });

    document.querySelector('#board_container').classList.add('board_expanded_proximity');
    simplebarManagement(".proximity_stoparea_routes_container.proximity_stoparea_routes_container_active");
}

const closeBoardStopPoint = () => {
    closeFullBoard();
    JKisio('#ctp-proximity-stoparea').hide();
}

const generateContentHeadHtml = (listModes) => {
    let html = '';

    listModes.forEach((mode, index) => {
        html += 
        `<div class="proximity_stoparea_mode ${ index === 0 ? "active" :  "" }" ${board_stoppoint_const.dataModeControllAttribute}=${mode.id}>
            ${getModePicto(mode.id)}
            ${mode.name}
        </div>`;
    })

    return html;
}

const generateContentHtml = (listModes, lines, stop) => {
    let html = '';
    let listLines = [];
    let stopArea = stop.stop_point.stop_area;

    let navitiaBuilder = new NavitiaBuilder();

    listModes.forEach((mode, i) => {
        html += `<div class="proximity_stoparea_routes_container ${i !== 0 ? "" : board_stoppoint_const.activeStopAreaRoutesContainerClassName } " ${board_stoppoint_const.dataModeAttribute}="${mode.id}">`;

        lines.forEach(function (l) {
            if (mode.id == l.commercial_mode.id) {
                let idLine = l.id;
                if (listLines.indexOf(idLine) === -1) {
                    listLines.push(idLine);

                    let star = new BookmarkStarElementBuilder({
                        type: "stop_area_lines_direction",
                        params: navitiaBuilder.stopAreas().setStopArea(
                            stopArea.id,
                            l.id,
                            mode.id,
                            l.code,
                            l.color,
                            l.text_color,
                            l.network.id,
                            stopArea.name
                        ).getNavitiaObject()
                    }).
                    buildHtml();
                    
                    html += 
                    `<div class="proximity_stoparea_route">
                        <div class="proximity_stoparea_route_title">
                            ${getModePicto(mode.id)} ${mode.name} ${getLinePicto(l, undefined, 'lines_in_board_proximity')}
                            ${star}
                        </div>
                        <div class="proximity_stoparea_route_stops">`;
                        
                        l.directions.forEach((d) => {
                            html += 
                            `<div class="proximity_stoparea_route_stop" data-direction="${d.direction.id}" data-line="${idLine}">
                                <div class="proximity_stoparea_route_stop_name">
                                    ${d.direction.name}
                                </div>
                                <div class="proximity_stoparea_route_stop_departures">
                                    ${Translator.trans('places_nearby.uri.errors.no-information')}
                                </div>
                            </div>`;
                        });
                        
                    html += `
                        </div>
                    </div>`;
                }
            }
        });

        html += '</div>';
    })

    return html;
}

const getNextDeparturesForStopArea = (route_id, stop_point_id, line_id) => {
    let nextDepartures = null; 
    JKisio.ajax({
      url: Routing.generate('proximity_departures', {
        type_product: window.Kisio.type_product !== undefined ? window.Kisio.type_product : '',
        route_id: route_id,
        stop_area_id: stop_point_id,
        stop_type: 'stop_points'
      }),
      async: false,
      dataType: "json",
      success: function (result) {
        nextDepartures = result;
      }
    });

    return nextDepartures;
}

const getNextDeparturesLineForStopPoint = async (line, stop_point_id) => {
    line.directions.forEach((direction) => {
        let nextDepartures = [];
        let timesDiff = {data_freshness: "base_schedule"}
    
        direction.routes.forEach((route) => {
            let response = getNextDeparturesForStopArea(route, stop_point_id, line.id);
            timesDiff = calculateDifferenceForNextDepartures(response);
            nextDepartures.push(...timesDiff.times)
        })

        if (nextDepartures.length > 0) {
            nextDepartures.sort((a, b) => a - b);

            updateDepartures(direction.direction.id, line.id, nextDepartures.slice(0, Math.min(3, nextDepartures.length)), timesDiff.data_freshness)
        }
    })
}

const calculateDifferenceForNextDepartures = (times) => {
    // Get the current date and time
    const currentDate = new Date();
    const currentHours = currentDate.getHours().toString().padStart(2, "0");
    const currentMinutes = currentDate.getMinutes().toString().padStart(2, "0");
    const currentTime = `${currentHours}:${currentMinutes}`; // Create an object to store the results
  
    const results = {
      times: [],
      data_freshness: times['data_freshness']
    }; // Loop through each time in the input array
  
    if (times['times']) {
      for (let i = 0; i < times['times'].length; i++) {
        const time = times['times'][i]; // access the i-th element of the 'times' array
        // Split the current time and the time from the input array into hours and minutes
  
        const [hour1, minute1] = currentTime.split(':');
        const [hour2, minute2] = time.split(':'); // Calculate the difference between the current time and the time from the input array in minutes
  
        const diffInMinutes = (parseInt(hour2) - parseInt(hour1)) * 60 + (parseInt(minute2) - parseInt(minute1)); // If the difference is less than 60 minutes, push the absolute difference in minutes to the results array
  
        if (Math.abs(diffInMinutes) < 60) {
          results['times'].push(`${Math.abs(diffInMinutes)}`);
        }
      }
    } // Return the results object
  
  
    return results;
}

const addEventListenerListModes = (element) => {
    let mode = element.getAttribute('data-mode-controll');
    document.querySelector('.'+board_stoppoint_const.activeStopAreaRoutesContainerClassName).classList.remove(board_stoppoint_const.activeStopAreaRoutesContainerClassName);
    document.querySelector("["+board_stoppoint_const.dataModeAttribute+"='"+mode+"']").classList.add(board_stoppoint_const.activeStopAreaRoutesContainerClassName);

    document.querySelector('.proximity_stoparea_mode_container .proximity_stoparea_mode.active').classList.remove("active");

    element.classList.add("active");
}

const updateDepartures = async (stop_area_id, line_id, nextDepartures, data_freshness) => {
    let el = document.querySelector(`.proximity_stoparea_route_stop[data-direction='${stop_area_id}'][data-line='${line_id}'] .proximity_stoparea_route_stop_departures`);
    if (el !== null) {
        let html = '';
        if(nextDepartures.length > 0) {
            if (data_freshness == "realtime") {
                html += `<img class="realtime" src="${Kisio.realtime_data_picto}" alt="Realtime picto" />`;
            }
            nextDepartures.forEach((t, index) => {
                html += `${index === 0 ? "" : ","} ${t}`
            })
            html += ` min`
            el.innerHTML = html;
        }
    }
}

const simplebarManagement = (elementIdentifiant) => {
    let content = document.querySelector(elementIdentifiant);

    if (content.SimpleBar) {
        content.SimpleBar.unMount();
    }
    new SimpleBar(content);
}
