import { parameters } from '../parameters';
import { enableFavorites } from '../modules/favorites';

/**
 * Return HTML for Fav Star Icon if Favorites are enabled and BSS Favorite are also
 * @param object
 * @returns {string}
 */
 export function getHTMLForBSSFavorite(object) {
    let favoriteBSS = '';
    let resultEnableFavorites = enableFavorites('bss');
    let enable_favorites = resultEnableFavorites[0];
    let favorites_manager = resultEnableFavorites[1];
    if (enable_favorites !== undefined && enable_favorites) {
        let bss_params = "{'id': '" + object.id + "','name': '" + object.name + "'}";
        let messageBSSAdd = Translator.trans('journey.result.favorites.bss.add');
        let messageBSSDel = Translator.trans('journey.result.favorites.bss.del');
        favoriteBSS = '<div class="fav-star fav-star-add fav-bss"'+
                            'data-favorites-manager="'+favorites_manager+'"' +
                            'data-favorites-type-section="proximity,journey"' +
                            'data-favorites-type="bss"' +
                            'data-favorites-params="'+bss_params+'"' +
                            'data-aria-label-add="'+messageBSSAdd+'"' +
                            'data-aria-label-del="'+messageBSSDel+'"' +
                            'tabIndex="0"' +
                            'title="'+messageBSSAdd+'"' +
                            'aria-label="'+messageBSSAdd+'">' +
        '</div>';
    }
    return favoriteBSS;
};

/**
 * Return HTML for Distance to Object (POI or Stop)
 * @param object
 * @returns {string}
 */
 export function getHTMLForDistanceObject(object) {
    let distancePOI;
    let infoDistance = '';
    if (object.distance !== undefined && object.distance !== '') {
        distancePOI = Math.ceil(parseInt(object.distance)/parseInt(Kisio.proximity_speed));
    }
    if (distancePOI !== undefined) {
        infoDistance = '<div class="proximity-poi-distance"><img class="freefloating-walk" src="'+(parameters.urlImages + 'modes/walk.png')+'" /><span>' + distancePOI + ' min</span></div>';
    }
    return infoDistance;
};