 export function displayLines(listModes, lines) {
    if (Kisio.layerGroup.proximity.stopPointsLines) {
        Kisio.layerGroup.proximity.stopPointsLines.clearLayers();
    } else {
        Kisio.layerGroup.proximity.stopPointsLines = new L.layerGroup();
    }
    let listLines = [];
    listModes.forEach((mode) => {
        lines.forEach(function (l) {
            if (mode.id == l.commercial_mode.id) {
                let lineId = l.id;
                if (listLines.indexOf(lineId) === -1) {
                    let geojsonListStops = '';

                    let route = Routing.generate('r_public_transport_objects_filtered', 
                        { 
                            type_product: (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : '', 
                            public_transport_object_type: 'lines', 
                            public_transport_object_id: lineId, 
                            action: 'routes' 
                        });
                    JKisio.ajax({
                        url: route,
                        async: false,
                        success: function (result) {
                            const stopPoints = result.routes[0].stop_points;

                            if (!stopPoints || stopPoints.length === 0) {
                                return;
                            }
                    
                            const group = L.featureGroup();
                            const listStopPoints = [];
                            const nameCount = {};
                    
                            stopPoints.forEach(stopPoint => {
                                nameCount[stopPoint.name] = (nameCount[stopPoint.name] || 0) + 1;
                            });
                    
                            stopPoints.forEach(function (stopPoint) {
                            if (!stopPoint.coord || !stopPoint.coord.lat || !stopPoint.coord.lon) {
                                return;
                            }
                
                            const label = nameCount[stopPoint.name] > 1 ? stopPoint.label : stopPoint.name;
                
                            if(listStopPoints.indexOf(label) !== -1) {
                                return;
                            }
                
                            listStopPoints.push(label);
                
                            const circleMarker = L.circleMarker(
                                [stopPoint.coord.lat, stopPoint.coord.lon], {
                                    radius: 5,
                                    width: 0.1,
                                    color: '#' + l.color,
                                    fillOpacity: 1,
                                    weight: 7,
                                    line_id: lineId,
                                }
                            ).addTo(group);
                
                            circleMarker
                                .on('mouseover', function (e) {
                                    const event = new CustomEvent("show_line_in_popup_event", {
                                        detail: { previousEvent: e, line: l, object: stopPoint}
                                    });
                                    document.dispatchEvent(event);
                                })
                            if (geojsonListStops === '') { geojsonListStops += '[' + stopPoint.coord.lon + ',' + stopPoint.coord.lat + ']'; }
                            else { geojsonListStops += ',[' + stopPoint.coord.lon + ',' + stopPoint.coord.lat + ']'; }
                            
                        });

                        Kisio.layerGroup.proximity.stopPointsLines.addLayer(group);

                            let line = result.routes[0].line;

                            let options = {
                                style: {
                                    color: "#" + line.color,
                                    weight: 5,
                                    opacity: 1
                                },
                                line_id: line?.id,
                                physical_mode: line?.physical_modes[0]?.id,
                                network_id: line?.network?.id,
                            };

                            if (result.routes[0].line.geojson.coordinates.length > 0) {
                                let traceLine = L.geoJSON(result.routes[0].line.geojson, options);
                                Kisio.layerGroup.proximity.stopPointsLines.addLayer(traceLine);
                            }
                            
                            Kisio.layerGroup.proximity.stopPointsLines.addTo(window.leaflet.map);

                        },
                        error: function (xhr, status, error) {
                            JKisio('#proximity-results').html('').append(error);
                            console.log(error);
                        }
                    });
                }
            }
        });

    });
   
};