import { initEventListeners, openProximity } from "./core/index";

document.body.addEventListener('initialize_proximity_module', function() {
    addEventOpenProximity();
    initEventListeners();
});

export function addEventOpenProximity() {
    document.body.removeEventListener('open_proximity_event', eventOpenProximityFunction);
    document.body.addEventListener('open_proximity_event', eventOpenProximityFunction, {once: true});
}

function  eventOpenProximityFunction() {
    document.body.dispatchEvent(new CustomEvent('stop_ajax_calls'));
    let initialCoords = Kisio.map_config.latitude+';'+Kisio.map_config.longitude;
    Kisio.section_active = "proximity";
    document.title = Translator.trans('proximity.title');
    let geolocate_cookie_refused = document.cookie.match(/nws_geolocation_refused=([^;]+)/);
    let geolocate_cookie_accepted = document.cookie.match(/nws_geolocation_accepted=([^;]+)/);

    if (Kisio.user_consent_position !== true && geolocate_cookie_accepted == null && (geolocate_cookie_refused === null || typeof geolocate_cookie_refused !== "undefined")) {
        openProximity(initialCoords);
        return;
    }
    
    if (Kisio.geolocate_on_first_load != '1' && (Kisio.user_consent != true || Kisio.user_consent_position == true) && JKisio('.kisio-global-popup').is(':visible')) {
        openProximity(initialCoords);
        return;
    }
    
    window.leaflet.map.locate({setView: true, maxZoom: 15})
        .on('locationfound', function(e){
            if (Kisio.section_active == "proximity" && !JKisio('#board').hasClass("expanded_proximity")) {
                initialCoords = e.latlng.lat+';'+e.latlng.lng;
                openProximity(initialCoords, true);
            }
            return;
        })
        .on('locationerror', function(e){
            if (Kisio.section_active == "proximity") {
                console.log(e);
                openProximity(initialCoords);
            }
        });
}