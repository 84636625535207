export var parameters = {
    urlImages : '/bundles/canaltppnpcore/images/',
    inputFromId : '#search_from_autocomplete',
    inputToId : '#search_to_autocomplete',
    longitude: window.Kisio?.map_config.longitude,
    latitude: window.Kisio?.map_config.latitude,
    zoom: window.Kisio?.map_config.zoom,
    max_zoom: window.Kisio?.map_config.max_zoom,
    zoom_control: window.Kisio?.map_config.zoom_control,
    icon_url: window.Kisio?.map_config.icon_url,
    icon_size: window.Kisio?.map_config.icon_size,
    popup_anchor: window.Kisio?.map_config.popup_anchor,
    tile_url: window.Kisio?.osm_map_tile_urls,
    tile_url_plan: window.Kisio?.osm_map_tile_urls,
    tile_url_satellite: window.Kisio?.osm_map_satellite,
    searchAutocompleteClass: '.journey-search-autocomplete',
    // L,
    // MarkerClusterGroup,
    // LayerGroup,
};