import { board_const } from "./constants";
import { initProximity } from '../proximity';
import { addMarker } from './manageProximity';
import { showResults } from './manageResults';
import { updateURLHistory } from "./utils";
import { getUrlParameter } from './manageUrl'
import { onClickCategory } from "../components/categories";
import { categories_const } from "../components/categories/constants";
import { poiFilterContainerDisplay } from "../components/categories";
import { initDetailsCategories } from "../components/categories/detailsCategories/";
import { createNewSearchBtn } from "../components/newSearchBtn";
import { launchManualProximityRequest } from "./manageProximity";
import { hideNewSearchBtn, disableEventNewSearch } from "../components/newSearchBtn";
import { clickMobileMinimizerInit } from "../components/minimizeButton/index";
import { proximityPopupOpen } from "./drawStopPoints";
import { deleteToggleStopArea, toggleStopAreaBuilder } from "../components/toggleStopArea/builder";
import { mobileResizeAutocompleteInit } from "../components/autocomplete/mobileResizeAutocomplete";
import { addEventOpenProximity } from "..";

var proximityContainer = null;

export function initEventListeners() {
    proximityContainer = document.querySelector(board_const.container);

    initProximity();

    JKisio(document).on('keyup', '#proximity_clear', function (e) {
        if (['Enter', 'NumpadEnter'].includes(e.key)) {
            JKisio(this).trigger('click');
        }
    });
    JKisio(document).on('click', '#proximity_clear', function () {
        closeProximity();
        document.body.dispatchEvent(new CustomEvent("go_to_board"));
        addEventOpenProximity();
    });

    JKisio(document).on('click', '#proximity-autocomplete-clear', function (e) {
        JKisio('#proximity_search_uri .popup').remove();
        JKisio('#proximity_search_uri_autocomplete').val('');
        JKisio('#proximity_search_uri_autocomplete-hidden').val('');
        document.body.dispatchEvent(new CustomEvent('hide_layers_section_event', {detail: {section: 'proximity', clean_data:true}}));
        JKisio('#proximity_search_uri_autocomplete').focus();
        JKisio("#proximity-results .poi_list").html('');
    });

    JKisio(document).on('keydown', '#proximity-search-form .autocomplete-clear', function (e) {
        if (['Enter', 'NumpadEnter'].includes(e.key)) {
            e.preventDefault();
            JKisio('#proximity-autocomplete-clear').click();
        }
    });

    JKisio(document).on('click', categories_const.categoriesClass, function (event) {
        onClickCategory(event, this);
    });

    initDetailsCategories();
    let locationString = decodeURIComponent(window.location);
    if (locationString.includes("/proximity/")) {
        JKisio('#footer_proximity').click();
    }

    document.body.addEventListener('hide_proximity_bundle', () => {
        hideProximity();
        JKisio('.ctp-proximity')[0].innerHTML = null;
    });
    
    document.body.addEventListener('proximity_close_section', () => {
        closeProximity();
        addEventOpenProximity();
    });
}

export function openProximity(
    initialCoords,
    isLocationFound = false
) {
    JKisio('#board_container').addClass('board_open_proximity');
    JKisio('#board_container').removeClass('board_open');
    JKisio('#board_container').removeClass('board_expanded_proximity');
    document.body.querySelector("#board_container").setAttribute('data-section', 'proximity');
    JKisio('#board').removeClass('expanded');
    JKisio('#board').addClass('expanded_proximity');
    document.body.dispatchEvent(new CustomEvent('display_layers_section_event', {detail: {section: 'proximity'}}));
    JKisio('#board_title_container').hide();
    JKisio('#board_footer').hide();

    JKisio('.leaflet-pane .leaflet-shadow-pane').show();
    JKisio('.leaflet-pane .leaflet-marker-pane').show();
    let div = JKisio(board_const.parentContainer);

    let params = {
        'proximity_search[distance]': Kisio.map_config.max_poi_search,
        'proximity_search[coords]': initialCoords,
        'proximity_search[uri][autocomplete-hidden]': '',
        'proximity_search[uri][autocomplete]': isLocationFound ? Translator.trans("places.autocomplete.position") : ''
    };

    let queryString = decodeURIComponent(window.location);

    // Set default query
    let queryFilters = typeof Kisio.default_filter_poi !== 'undefined' ? Kisio.default_filter_poi : [];

    if (queryString.includes("/proximity/")) {
        // let queryString = decodeURIComponent(window.location.search);
        if (queryString !== '') {
            queryFilters = getUrlParameter(queryString, 'filter');
            let queryCoords = getUrlParameter(queryString, 'proximity_search[coords]');
            let queryAutocompleteHiddenValue = getUrlParameter(queryString, 'proximity_search[uri][autocomplete-hidden]');
            let queryAutocompleteValue = getUrlParameter(queryString, 'proximity_search[uri][autocomplete]');
            let showStops = getUrlParameter(queryString, 'proximity_search[show_stops]');

            params = {
                // 'proximity_search[distance]': queryDistance == '' ? params["proximity_search[distance]"] : queryDistance,
                'proximity_search[coords]': queryCoords == '' ? params["proximity_search[coords]"] : queryCoords,
                'proximity_search[uri][autocomplete-hidden]': queryAutocompleteHiddenValue == '' ? params["proximity_search[uri][autocomplete-hidden]"] : queryAutocompleteHiddenValue,
                'proximity_search[uri][autocomplete]': queryAutocompleteValue == '' ? params['proximity_search[uri][autocomplete]'] : queryAutocompleteValue,
                'proximity_search[show_stops]': showStops === '' ? true : showStops
            }
        }
    } else {
        updateURLHistory("/" + Kisio.locale + "/" + Kisio.type_product);
        queryFilters = Kisio['default_filter_poi'];
    };

    let query = JKisio.param(params);

    if (queryFilters !== undefined && queryFilters.length > 0) {
        for (const filter of queryFilters) {
            query += '&proximity_search[filter][]=' + filter;
        }
    }

    let route = Routing.generate('proximity_display', {
        type_product: (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : ''
    }) + '?' + query;

    JKisio.ajax({
        url: route,
        success: function (result) {
            // Display search bar
            div.show();

            // if (Kisio.type_product !== 'standalone') {
            proximityContainer.innerHTML += result.html
            // }

            // Enable autocomplete
            JKisio('#proximity_search_uri_autocomplete').autocomplete();
            Kisio.Callbacks.autocomplete();

            if (JKisio('#result_content').length > 0 && JKisio('#result_content').is(':visible')) {
                JKisio('#result_content').hide();
            }

            addMarker(params['proximity_search[coords]']);
            let object_coord = params['proximity_search[coords]'].split(';');
            Kisio.map_config.lat = Kisio.map_config.latitude;
            Kisio.map_config.long = Kisio.map_config.longitude;
            if (typeof object_coord !== 'undefined' && Object.keys(object_coord).length > 1) {
                Kisio.map_config.lat = object_coord[0];
                Kisio.map_config.long = object_coord[1];
            }

            // Creer le btn new search
            createNewSearchBtn();
            
            //Display toggleStopArea
            let toggle = new toggleStopAreaBuilder({}).buildHtml();
            JKisio('body').append(toggle);

            // Display Poi on the map
            showResults(result);

            // Resize POI container
            poiFilterContainerDisplay();

            updateURLHistory(route);

            clickMobileMinimizerInit();

            mobileResizeAutocompleteInit();

            document.body.dispatchEvent(new CustomEvent("bookmark_link_init"));

            let categorySelected = document.querySelector('.ctp-proxi-accordion-min.active_category');
            if (queryFilters === undefined || queryFilters.length === 0) {
                let filtersDataset = JSON.parse(categorySelected.dataset.filters);
                queryFilters = Object.keys(filtersDataset);
            }else if (queryFilters !== undefined || queryFilters.length !== 0) {
                categorySelected.classList.remove('active_category');
                let buttonsFilters = document.querySelectorAll('.ctp-proxi-accordion-min:not(#ctp-proxi-more)');
                buttonsFilters.forEach((button) => {
                    if(button.dataset.filters.includes(queryFilters[0])) {
                        button.classList.add('active_category');
                        return;
                    }
                });
            }

            launchManualProximityRequest(
                params["proximity_search[uri][autocomplete-hidden]"],
                params["proximity_search[uri][autocomplete]"],
                params["proximity_search[coords]"],
                Kisio.map_config.max_poi_search,
                queryFilters,
                params["proximity_search[show_stops]"]
            )
        },
        error: function () {
            console.log('Error in loading Proximity template');
        },
        async: true
    });
};


export function hideProximity() {
    JKisio('#board_container').removeClass('board_open_proximity');
    JKisio('#board').removeClass('expanded_proximity');
    JKisio('#ctp-proximity').hide();

    JKisio('.proximity-standalone')?.hide();
}

function displayBoard() {
    JKisio('#board_container').removeClass('board_open_proximity');
    JKisio('#board_container').removeClass('board_expanded_proximity');
    JKisio('#board').removeClass('expanded_proximity');

    JKisio('#board_title_container').show();
    JKisio('#board_footer').show();
    JKisio(board_const.parentContainer).hide()
}

export function closeProximity() {
    Kisio.section_active = "";
    disableEventNewSearch();
    hideNewSearchBtn();
    displayBoard();

    document.body.dispatchEvent(new CustomEvent('hide_layers_section_event', {detail: {section: 'proximity', clean_data:true}}));

    deleteToggleStopArea();
    JKisio('.proximity-poi-icon').remove();
    window.leaflet.map.off('popupopen', proximityPopupOpen);
    if (proximityContainer !== null) {
        proximityContainer.innerHTML = '';
    }
    updateURLHistory("/" + Kisio.locale + "/" + Kisio.type_product);
}