import { displayStopPointPopUp } from '../components/board/boardStopPoint'
import {toggleStopAreaBuilder} from "../components/toggleStopArea/builder";

export const icon_default_config = {
    iconSize: 16,
    iconAnchor: [4, 30],
    shadowSize: 37,
    shadowAnchor: [15, 35],
    popupAnchor: [4, -25],
    className: 'proximity-poi-icon'
};

/**
 * Draw on Map the Stop Points
 * @param stops
 */
export function drawStopPoints(stops, showStops) {
    let icon;

    // parameters.urlImages in  'pnp_core_bundle/plugnplay/leaf-core';
    let urlImages = '/bundles/canaltppnpcore/images/';
    let iconDefaultURL = urlImages + 'pin-transport.svg';

    let _this = this;
    let iconURL = urlImages + 'pin-transport.svg';
    let title = Translator.trans('proximity.map.pointType.stop_point');
    for (const stop of stops) {
        if (stop['stop_point'] !== undefined && stop['stop_point']['coord'] !== undefined && Object.keys(stop['stop_point']['coord']).length > 1) {
            let object_coord = stop['stop_point']['coord'];
            // Test if mode TC is defined
            if (stop['stop_point']['commercial_modes'] !== undefined) {
                let mode = stop['stop_point']['commercial_modes'][0]['name'].toLowerCase();
                iconDefaultURL = Kisio.modes_img[mode];
                if (typeof Kisio.modes_img[mode] === 'undefined') {
                    iconDefaultURL = Kisio.modes_img['commercial_mode:' + mode];
                }

                if (typeof iconDefaultURL === "undefined") {
                    iconURL = urlImages + 'pin-transport.svg';
                }

                icon = L.icon({
                    iconUrl: iconDefaultURL,
                    shadowUrl: iconURL,
                    ...icon_default_config
                });
            } else {
                // In case we don't have mode TC
                iconDefaultURL = urlImages + 'default-stop.svg';
                icon = L.icon({
                    iconUrl: iconDefaultURL,
                    shadowUrl: iconURL,
                    ...icon_default_config
                });
            }
            let markerStop = L.marker([object_coord.lat, object_coord.lon], {
                icon: icon,
                title: title + stop.name,
                keyboard: false,
                id: stop['stop_point'].id
            });
            JKisio(markerStop).data('stop-object', stop).data('icon-poi-url', iconDefaultURL);
            Kisio.layerGroup.proximity.stopPointGroupLayer.addLayer(markerStop);
            markerStop.on('click', function () {
                displayStopPointPopUp(this);
            })
        }
    }

    window.leaflet = window.leaflet || {};

    if(!window.leaflet.map){
        document.body.dispatchEvent(new CustomEvent('initialize_map'));
    }

    window.leaflet.map._layersMaxZoom = window.Kisio?.map_config.max_zoom;

    if (showStops !== "false") {
        Kisio.layerGroup.proximity.stopPointGroupLayer.addTo(window.leaflet.map);
    }

    document.getElementById('toggleStopArea').innerHTML = new toggleStopAreaBuilder({}).html();
    window.leaflet.map.on('popupopen', proximityPopupOpen);
};

export function proximityPopupOpen (e) {
    let popupHtml = e.popup._content;
    if (popupHtml.indexOf('nocontent') !== -1) {
        let tip = JKisio(e.popup._tip);
        tip.css('background-color', '#f0f0f0');
    }
}