import { newSearchButtonConst } from "./constants";
import { launchManualProximityRequest } from "../../core/manageProximity";
import { getUrlParameter } from "../../core/manageUrl";
import { updateBoardWithPoi } from "../board/boardPoi";

export const showNewSearchBtn = () => {
    if (JKisio('#proximity_content').is(':visible')) {
        document.querySelector('#'+newSearchButtonConst.id)?.classList.remove(newSearchButtonConst.classHide);
    }
}

export const hideNewSearchBtn = () => {
    document.querySelector('#'+newSearchButtonConst.id)?.classList.add(newSearchButtonConst.classHide);
}

export const createNewSearchBtn = () => {
    if (document.querySelector('.'+newSearchButtonConst.classHide)) {
        return;
    }

    const newSearchBtn = document.createElement('div');
    newSearchBtn.className = newSearchButtonConst.classHide;
    newSearchBtn.id = newSearchButtonConst.id;
    newSearchBtn.tabIndex = "0";
    newSearchBtn.innerHTML = '<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M16.5915 3.40834C14.6898 1.50667 11.9948 0.410004 9.03149 0.713337C4.74982 1.145 1.22649 4.62167 0.748155 8.90334C0.106488 14.5617 4.48149 19.3333 9.99982 19.3333C13.7215 19.3333 16.9182 17.1517 18.4115 14.0133C18.7848 13.2317 18.2248 12.3333 17.3615 12.3333C16.9298 12.3333 16.5215 12.5667 16.3348 12.9517C15.0165 15.7867 11.8548 17.5833 8.40149 16.8133C5.81149 16.2417 3.72316 14.13 3.17482 11.54C2.19482 7.01334 5.63649 3 9.99982 3C11.9365 3 13.6632 3.805 14.9232 5.07667L13.1615 6.83834C12.4265 7.57334 12.9398 8.83334 13.9782 8.83334H18.1665C18.8082 8.83334 19.3332 8.30834 19.3332 7.66667V3.47834C19.3332 2.44 18.0732 1.915 17.3382 2.65L16.5915 3.40834Z" fill="white"/></svg>';
    newSearchBtn.innerHTML += Translator.trans('proximity.form.newSearch');
    document.querySelector('body').prepend(newSearchBtn);

    newSearchBtn.addEventListener('click', () => {
        newSearch();
    })

    newSearchBtn.addEventListener('keydown', (event) => {
        if (['Enter', 'NumpadEnter'].includes(event.key)) {
            newSearch();
        }
    })

    enableEventNewSearch();
}

const newSearch = () => {
    let distance = Kisio.map_config.max_poi_search;
    let filters = [];

    let queryString = decodeURIComponent(window.location.search);

    filters = getUrlParameter(queryString, 'filter');
    const showStops = getUrlParameter(queryString, 'proximity_search[show_stops]');

    let autocomplete = ' ';
    let uriAutocompleteElement = JKisio('#proximity_search_uri_autocomplete')[0];
    uriAutocompleteElement.value = '';
    
    let centerMap = window.leaflet.map.getCenter();
    let coords = centerMap.lat+";"+centerMap.lng;
    let autocompleteHidden = centerMap.lng+";"+centerMap.lat;

    JKisio('#proximity_search_uri_autocomplete-hidden').val(autocompleteHidden);
    uriAutocompleteElement.dataset.coord = coords;
    
    let result = launchManualProximityRequest(
        autocompleteHidden,
        autocomplete,
        coords,
        distance,
        filters,
        showStops
    );

    updateBoardWithPoi(result);
}

export const enableEventNewSearch = () => {
    window.leaflet.map.on("moveend", showNewSearchBtn);
    window.leaflet.map.on("zoomed", showNewSearchBtn);
}

export const disableEventNewSearch = () => {
    window.leaflet.map.off("moveend", showNewSearchBtn);
    window.leaflet.map.off("zoomed", showNewSearchBtn);
}