import { addQueryParametersToRoute } from "../../core/manageProximity";
import { setURLDynamically } from "../../core/manageUrl";

export const toggleStopAreaConst = {
    id: "toggleStopArea",
    showStop:'proximity_search[show_stops]'
}

export const toggleStopArea = () => {
    let queryString = decodeURIComponent(window.location.search);
    let params = new URLSearchParams(queryString);

    if (!params.has(toggleStopAreaConst.showStop)) {
        params.set(toggleStopAreaConst.showStop, 'true');
    }

    params.set(toggleStopAreaConst.showStop, 
        params.get(toggleStopAreaConst.showStop) == "true" ? "false" : "true"
    );

    let route = addQueryParametersToRoute(params.toString());

    setURLDynamically("?"+route);

    if (params.get(toggleStopAreaConst.showStop) == 'false') {
        Kisio.layerGroup.proximity.stopPointGroupLayer.removeFrom(window.leaflet.map);
        return;
    }

    Kisio.layerGroup.proximity.stopPointGroupLayer.addTo(window.leaflet.map);
    return;
}