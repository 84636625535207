import { showResultsOnMap, showResults } from './manageResults';
import { updateURLHistory } from './utils';
import {getUrlParameter, setURLDynamically} from './manageUrl';
import { enableEventNewSearch, disableEventNewSearch, hideNewSearchBtn } from '../components/newSearchBtn'
import { getActiveCategoryFilter } from '../components/categories';

/**
 * Launch Update Process
 */
export function launchUpdateProcess() {

    JKisio('#proximity_search_uri .popup').remove();
    if (checkAutocompletesValues()) {
        if (JKisio('#ctp-proximityPoiForm input[type=checkbox]:checked').length) {
            const queryString = decodeURIComponent(window.location.search);
            const showStops = getUrlParameter(queryString, 'proximity_search[show_stops]');
            if (JKisio('#proximity-show-hide-stops button').hasClass('hideStops')) {
                showStops = 'true';
            } else if (JKisio('#proximity-show-hide-stops button').hasClass('showStops')) {
                showStops = 'false';
            }
            document.body.dispatchEvent(new CustomEvent('hide_layers_section_event', {detail: {section: 'proximity', clean_data:true}}));
            JKisio('#loading-box').css("visibility", "visible");
            setTimeout(function () {
                const coords = JKisio('#proximity_search_uri_autocomplete').attr('data-coord');
                const queryParams = {
                    proximity_search: {
                        coords: coords,
                        distance: JKisio('#proximity-search-time-input input:checked').val()
                    }
                };

                updateProximityRequest(showStops, queryParams);
                JKisio('#loading-box').css("visibility", "hidden");
            }, 300);
        } else {
            Kisio.layerGroup.proximity.poiGroupLayer.clearLayers();
        }
    }
};


/**
 * Launch request and show results
 */
export function launchProximityRequest(distance, filters, showStops) {
    hideNewSearchBtn();
    JKisio('#proximity_search_uri .popup').remove();
    if (checkAutocompletesValues()) {
        // Launch request
        let params = {
            'proximity_search[uri][autocomplete-hidden]': JKisio('#proximity_search_uri_autocomplete-hidden').val(),
            'proximity_search[uri][autocomplete]': JKisio('#proximity_search_uri_autocomplete').val(),
            'proximity_search[distance]': distance,
            'proximity_search[coords]': JKisio('#proximity_search_uri_autocomplete').attr('data-coord')
        };
        let query = JKisio.param(params);
        if (filters !== undefined && filters.length > 0) {
            for (const filter of filters) {
                query += '&proximity_search[filter][]=' + filter;
            }
        }
        let route = Routing.generate('proximity_form_result', { type_product: (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : '' }) + '?' + query;
        JKisio.ajax({
            url: route,
            async: false,
            success: function (result) {
                JKisio('#div_show_poi_list').show().promise().done(function () {
                    if (result.data.result_map !== undefined) {
                        showResultsOnMap(result.data.result_map, showStops);
                    }
                    if (coords !== undefined) {
                        addMarker(coords);
                    }
                    route = addQueryParametersToRoute(route);
                    updateURLHistory(route);
                });
            },
            error: function (xhr, status, error) {
                JKisio('#proximity-results').html('').append(error);
                console.log(error);
            }
        });
    }
};

/**
 * Launch request and show results
 */
export function launchManualProximityRequest(
    autocompleteHidden,
    autocomplete,
    coords,
    distance,
    filters,
    showStops
) {
    hideNewSearchBtn();
    let params = {
        'proximity_search[uri][autocomplete-hidden]': autocompleteHidden,
        'proximity_search[uri][autocomplete]': autocomplete,
        'proximity_search[distance]': distance,
        'proximity_search[coords]': coords,
        'proximity_search[show_stops]': showStops ?? true,
    };
    let query = JKisio.param(params);
    if (filters !== undefined && filters.length > 0) {
        for (const filter of filters) {
            query += '&proximity_search[filter][]=' + filter;
        }
    }

    let route = Routing.generate('proximity_form_result', { type_product: (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : '' }) + '?' + query;
    let returnData = null;
    JKisio.ajax({
        url: route,
        async: false,
        success: function (result) {
            route = addQueryParametersToRoute(route);
            updateURLHistory(route);
            if (result.data) {
                returnData = [];
                Object.values(result.data.result_map).forEach(function (item) {
                    returnData.push(...item.response);
                });
                document.body.dispatchEvent(new CustomEvent('hide_layers_section_event', {detail: {section: 'proximity', clean_data:true}}));

                if (coords !== undefined) {
                    addMarker(coords);
                }
                result.html = undefined;

                if (result.data.result_map !== undefined) {
                    showResultsOnMap(result.data.result_map, showStops);
                }
            }
        },
        error: function (xhr, status, error) {
            JKisio('#proximity-results').html('').append(error);
            console.log(error);
        }
    });

    return returnData;
};

/**
 *
 * @param elt
 */
export function launchProximitySearch(elt) {
    JKisio('#proximity_search_uri .popup').remove();
    if (JKisio('#ctp-searchProximity').is(':visible') && (JKisio('#proximity_content').length > 0 || JKisio('.proximity-standalone').length > 0)) {
        let that = elt;
        if (JKisio('#ctp-proximityPoiForm').length > 0) {
            if (that !== undefined) {
                JKisio('#ctp-proximityPoiForm #proximity_search_uri_autocomplete').val(JKisio(that).attr('title'));
                JKisio('#ctp-proximityPoiForm #proximity_search_uri_autocomplete-hidden').val(JKisio(that).attr('id'));
            } else if (JKisio('#proximity_search_uri #proximity_search_uri_autocomplete-hidden').val() !== undefined && JKisio('#proximity_search_uri #proximity_search_uri_autocomplete').val() !== undefined) {
                JKisio('#ctp-proximityPoiForm #proximity_search_uri_autocomplete').val(JKisio('#proximity_search_uri #proximity_search_uri_autocomplete').val());
                JKisio('#ctp-proximityPoiForm #proximity_search_uri_autocomplete-hidden').val(JKisio('#proximity_search_uri #proximity_search_uri_autocomplete-hidden').val());
            }
        }
        JKisio('#loading-box').css("visibility", "visible");

        const queryString = decodeURIComponent(window.location.search);
        const showStops = getUrlParameter(queryString, 'proximity_search[show_stops]');

        document.body.dispatchEvent(new CustomEvent('hide_layers_section_event', {detail: {section: 'proximity', clean_data:true}}));
        setTimeout(() => {

        }, 300)

        let coords;
        if (that !== undefined && typeof JKisio(that).data('coord') !== 'undefined') {
            coords = JKisio(that).data('coord');
        } else if (JKisio('#proximity_search_uri #proximity_search_uri_autocomplete-hidden').data('coord') !== undefined) {
            let coord_object = JKisio('#proximity_search_uri_autocomplete-hidden').data('coord');
            coords = coord_object.lat + ';' + coord_object.lon;
        }else if (JKisio('#proximity_search_uri_autocomplete').data('coord') !== undefined) {
            let coord_object = JKisio('#proximity_search_uri_autocomplete').data('coord');
            coords = coord_object;
        }
        if (coords !== undefined) {
            JKisio('#proximity_search_uri_autocomplete').attr('data-coord', coords);
            addMarker(coords);
        }
        const queryParams = {
            proximity_search: {
                coords: coords,
                distance: Kisio.map_config.max_poi_search,
                uri: {
                    'autocomplete-hidden': JKisio('#proximity_search_uri_autocomplete-hidden').val(),
                    autocomplete: JKisio('#proximity_search_uri_autocomplete').val()
                }
            }
        };
        updateProximityRequest(showStops, queryParams);
        JKisio('#loading-box').css("visibility", "hidden");
    }
};

/**
 * Create Proximity Button for POI & StopPoints PopUp
 * @param elt
 * @param name
 * @param autocompleteHidden
 */
export function createProximityButton(elt, name, autocompleteHidden, queryShowStops) {
    let queryInit = decodeURIComponent(JKisio('#ctp-proximityPoiForm').serialize());
    let regex = /proximity_search\[uri\]\[autocomplete\]=^$|(.+)&proximity_search\[uri\]\[autocomplete-hidden\]=^$|(.+)/gm;
    let subst = 'proximity_search[uri][autocomplete]=' + name.replace(/#/g, '%23') + '&proximity_search[uri][autocomplete-hidden]=' + autocompleteHidden.replace(/#/g, '%23');
    let query = queryInit.replace(regex, subst);
    query += '&proximity_search[coords]=' + elt.lat + ';' + elt.lon;
    query += queryShowStops;
    let href = Routing.generate('proximity_form_result', { type_product: (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : '' }) + '?' + query;
    return '<a href="' + href + '"><button id="popup-proximity-btn" class="ikisio ikisio-proximity"> ' +
        '<span>' + Translator.trans('proximity.form.poi.title') + '</span></button></a>';
};


/**
 *  Update request and update results
 */
export function updateProximityRequest(showStops, queryParams) {
    let queryInit = JKisio('#ctp-proximityPoiForm').serialize() + '&' + JKisio.param(queryParams);
    queryInit += '&proximity_search[show_stops]=' + showStops ?? true;
    let filters = getActiveCategoryFilter();
    if (filters !== undefined && filters.length > 0) {
        for (const filter of filters) {
            queryInit += '&proximity_search[filter][]=' + filter;
        }
    }
    
    let urlUpdate = Routing.generate(
        'proximity_form_result',
        { type_product: (Kisio.type_product !== undefined) ? Kisio.type_product : '' }) + '?' + queryInit;
    // Launch request
    JKisio.ajax({
        url: urlUpdate,
        async: false,
        success: function (result) {
            if (result.data && result.data.result_map !== 'undefined') {
                const coords = queryParams.proximity_search.coords.split(";");
                showResults(result, coords[0], coords[1]);
            }
            let route = addQueryParametersToRoute(urlUpdate);
            setURLDynamically(route);
        },
        error: function (xhr, status, error) {
            JKisio('#proximity-results').html('').append(error);
            console.log(error);
        }
    });
};


/**
 * Check if autocompletes Values are filled and show error popup if not
 * @returns {boolean}
 */
export function checkAutocompletesValues() {
    let messageAria;
    let message;
    let showMessage = false;
    if (JKisio('#proximity_search_uri_autocomplete-hidden').val() === '') {
        if (JKisio('#proximity_search_uri_autocomplete').val() === '') {
            message = Translator.trans('places_nearby.uri.errors.blank');
            messageAria = Translator.trans('places_nearby.uri.errors.blankAria');
        } else {
            message = Translator.trans('places_nearby.uri.errors.no-solution');
            messageAria = Translator.trans('places_nearby.uri.errors.no-solution');
        }
        showMessage = true;
    } else {
        if (JKisio('#proximity_search_uri_autocomplete').val() === '') {
            message = Translator.trans('places_nearby.uri.errors.no-solution');
            messageAria = Translator.trans('places_nearby.uri.errors.no-solution');
            showMessage = true;
        }
    }
    if (showMessage) {
        if (JKisio('#error_popup').length > 0) {
            JKisio('#error_popup').remove();
        }
        let html = "<div class='popup show'><div id='error_popup'><span class='popuptext' id='myPopup' tabindex='0'>" +
            "<i class='ikisio ikisio-warning' aria-hidden='true'></i>" +
            "<span aria-hidden='true'>" + message + "</span>" +
            "<span class='sr-only' aria-live='assertive'>" + messageAria + "</span>" +
            "</span></div></div>";
        JKisio(html).insertBefore('#proximity_search_uri_autocomplete');
        JKisio('#myPopup').focus();
        return false;
    } else {
        return true;
    }
};

/**
 * Add supplemental parameters to query for rewriting GET URL
 * @param route
 * @returns {*}
 */
export function addQueryParametersToRoute(route) {
    if (route.indexOf('coords') === -1) {
        route += '&proximity_search[coords]=' + JKisio('#proximity_search_uri_autocomplete').attr('data-coord');
    }
    // if (JKisio('#proximity-show-hide-stops button').hasClass('hideStops')) {
    //     route += '&proximity_search[show_stops]=true';
    // } else if (JKisio('#proximity-show-hide-stops button').hasClass('showStops')) {
    //     route += '&proximity_search[show_stops]=false';
    // }
    return route;
};


/**
 * Add marker on map
 * @param coords
 */
export function addMarker(coords) {
    if (typeof coords !== 'undefined') {
        let icon = L.icon({
            iconUrl: '/bundles/canaltppnpcore/images/' + 'map-marker-from.svg',
            iconSize: window.Kisio?.map_config.icon_size 
        });
        let object_coord = coords.split(';');
        if (typeof object_coord !== 'undefined' && Object.keys(object_coord).length > 1) {

            Kisio.layerGroup.proximity?.marker?.removeFrom(window.leaflet.map);
            Kisio.layerGroup.proximity.marker = new L.layerGroup();
            Kisio.layerGroup.proximity.marker.addLayer(L.marker([object_coord[0], object_coord[1]], { icon: icon, keyboard: false }));            
            Kisio.layerGroup.proximity.marker.addTo(window.leaflet.map);
        }
    }
};