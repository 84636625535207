const classOpen = "board_open_autocomplete";

export const mobileResizeAutocompleteInit = () => {
    let autocomplete = document.querySelector('.ctp-proximity-home .autocomplete .autocompletable');
    let board = document.querySelector('#board_container'); 

    if (autocomplete !== null) {
        autocomplete.addEventListener('keydown', () => {
            board.classList.add(classOpen);        
        });
        autocomplete.addEventListener('blur', function() {
            board.classList.remove(classOpen);
        });
    }
}

export const closeAutocomplete = () => {
    document.querySelector('#board_container').classList.remove(classOpen);
}