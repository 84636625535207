import { drawStopPoints } from './drawStopPoints';
import { drawPOIs } from '../components/map/marker';
import { enableEventNewSearch, disableEventNewSearch, hideNewSearchBtn } from '../components/newSearchBtn'
import {getUrlParameter} from "./manageUrl";

/**
 * Show POIs and Stops on Map
 * @param data
 */
 export function showResultsOnMap(data, showStops) {
    let stops = [];
    let pois = [];
    if (data['proximity.form.poi.group.stop'] !== undefined && data['proximity.form.poi.group.stop']['response'] !== undefined && data['proximity.form.poi.group.stop']['response'].length > 0) {
        let response = data['proximity.form.poi.group.stop']['response'];
        for (const stopGroup of response) {
            if (stopGroup.places_nearby !== undefined && stopGroup.places_nearby.length > 0) {
                stops = stopGroup.places_nearby;
            }
        }
        data['proximity.form.poi.group.stop']['response'][0]['category'] = 'stops';
        data['proximity.form.poi.group.stop']['response'][0]['poi_type'] = 'stops';
        // delete data['proximity.form.poi.group.stop'];
    }

    drawStopPoints(stops, showStops);

    if (!JKisio.isEmptyObject(data)) {
        for (let poiGroup in data) {
            if (data[poiGroup].response !== undefined && data[poiGroup].response.length > 0) {
                let response = data[poiGroup].response;
                for (const poiSubGroup of response) {
                    if (poiSubGroup.places_nearby !== undefined && poiSubGroup.places_nearby.length > 0) {
                        let places = poiSubGroup.places_nearby;
                        for (const place of places) {
                            place.poi_type = poiSubGroup.poi_type;
                            pois.push(place);
                        }
                    }
                    if (poiSubGroup.free_floatings !== undefined && poiSubGroup.free_floatings.length > 0) {
                        let floatings = poiSubGroup.free_floatings;
                        for (const floating of floatings) {
                            floating.poi_type = poiSubGroup.poi_type;
                            pois.push(floating);
                        }
                    }

                }
            }
        }
    }
    if (pois.length > 0) {
        drawPOIs(pois);
    }
};

export function showProximityResultContent(div, callback) {
    const height = JKisio(div).css({
        display: "block"
    }).height();

    JKisio(div).css({
        marginTop: 0,
        height: height
    }).animate({
        marginTop: 0,
        height: 0
    }, 500, function () {
        JKisio(this).css({
            display: "block",
            height: "",
            marginTop: ""
        }).hide().promise().then(function () {
            JKisio('#ctp-proximity-poi').show().promise().then(function () {
                resetHeight();
                if (typeof callback === "function") {
                    callback();
                }
            });
        });
    });
};

/**
 * Hide proximity results content and show poi lists
 * @param div
 */
 export function hideProximityResultContent(div, callback) {
    const height = JKisio(div).height();

    JKisio(div).css({
        marginTop: height,
        height: 0
    }).animate({
        marginTop: 0,
        height: 0
    }, 500, function () {
        JKisio(this).css({
            display: "none",
            height: "",
            marginTop: ""
        });
    });
    JKisio('.poi_list').show().promise().then(function () {
        JKisio('#proximity-results').css({'height': 'auto'});
        window.proximitySimpleBar.recalculate();
        JKisio('.prox-departure-btn, .prox-arrival-btn, .prox-recenter-btn').tooltip();
        if (typeof callback === "function") {
            callback();
        }
    });
};

export function showResults(
    result,
    lat = null,
    long = null
) {
    let latitude = lat || Kisio.map_config.lat;
    let longitude = long || Kisio.map_config.long;
    const coords = latitude+';'+longitude;
    const inverted_coords = longitude+';'+latitude;
    const input = JKisio('#proximity_search_uri_autocomplete').attr('data-coord', coords);//.val(coords);
    JKisio('#proximity_search_uri_autocomplete-hidden').val(inverted_coords);
    JKisio('.autocomplete-clear, .autocomplete-clear i').show();
    if (!JKisio('#proximity-results').is(':visible')) {
        JKisio('#proximity-results').show();
    }
    if (!JKisio('#div_show_poi_list').is(':visible')) {
        JKisio('#div_show_poi_list').show();
    }

    const queryString = decodeURIComponent(window.location.search);
    const showStops = getUrlParameter(queryString, 'proximity_search[show_stops]');
    if (result.data) {
        showResultsOnMap(result.data.result_map, showStops);
    }

    disableEventNewSearch();
    hideNewSearchBtn();
    window.leaflet.map.flyTo([latitude,longitude], window.Kisio?.map_config.max_zoom, {
        duration: 0.5
    });
    
    const callBackFlyTo = setInterval(() => {
        if (!window.leaflet.map._animating) {
            clearInterval(callBackFlyTo);
            window.leaflet.map.once('moveend', function() {
                enableEventNewSearch();
            });
        }
    }, 250);
};

function resetHeight (){
    JKisio('#proximity-results').css({'height': 'auto'});
    window.proximitySimpleBar.recalculate();
}