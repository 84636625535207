const idMobileMinimizer = "mobile-minimizer";

export const clickMobileMinimizerInit = () => {
    if (document.querySelector('.board_open_proximity') < 0) {
        return;
    }

    let element = document.getElementById(idMobileMinimizer);
    element.addEventListener('click', toggleBoard)
}

const toggleBoard = () => {
    let board = document.querySelector('#board_container');
    board.classList.toggle('board_expanded_proximity');
}