import { parameters } from '../../parameters';
import { getHTMLForBSSFavorite, getHTMLForDistanceObject } from '../../core/htmlUtils';
import { displayBoardSinglePoi } from '../board/boardPoi';
import { ButtonDepartureArrival } from "journey_bundle/modules/buttonDepartureArrival/builder";

export const icon_default_config = {
    iconSize: 24,
    iconAnchor: [4, 30],
    shadowSize: 47,
    shadowAnchor: [16, 34],
    popupAnchor: [10, -15],
    className: 'proximity-poi-icon'
};

const icon_vls_default_config = {
    iconSize: 30,
    iconAnchor: [8, 31],
    shadowSize: 47,
    shadowAnchor: [16, 34],
    popupAnchor: [10, -15],
    className: 'proximity-poi-icon'
};

/**
 * Draw on Map the POIs
 * @param pois
 */
export function drawPOIs(pois) {
    
    let urlStr;
    let iconPOIUrl;
    let title;
    let object_coord;
    let markerIcon;
    let iconPOI;

    let iconURL = parameters.urlImages + 'pin-proximity.svg';
    Kisio.layerGroup.proximity.poiGroupLayer.clearLayers();
    let regex = /(asset->)/gm;
    let subst = "/";
    for (const poi of pois) {
        if (poi['poi'] !== undefined && poi['poi']['coord'] !== undefined && Object.keys(poi['poi']['coord']).length > 1) {
            object_coord = poi['poi']['coord'];
            title = Translator.trans('proximity.map.pointType.poi');
            iconPOIUrl = parameters.urlImages + 'poi.png';
            if (poi.poi_type in Kisio.proximityMarkerPoiType) {
                title = Translator.trans(Kisio.proximityMarkerPoiType[poi.poi_type]['trad_key']);
                urlStr = Kisio.proximityMarkerPoiType[poi.poi_type]['url_img'];
                iconPOIUrl = urlStr.replace(regex, subst);
            }
            title = title === '' ? poi.name : title + ' - ' + poi.name;

            iconPOI = L.icon({
                iconUrl: iconPOIUrl,
                shadowUrl: iconURL,
                ...(poi.poi_type == "amenity:bicycle_rental" ? icon_vls_default_config : icon_default_config)
            });
            
            markerIcon = L.marker([object_coord.lat, object_coord.lon], {
                id: poi.id,
                icon: iconPOI,
                title: title,
                keyboard: false,
                layerType: 'proximity',
                layer_section: ['proximity'],
                alt: title
            });
            JKisio(markerIcon).data('poi-object', poi).data('icon-poi-url', iconPOIUrl);
            Kisio.layerGroup.proximity.poiGroupLayer.addLayer(markerIcon);
            markerIcon.on('click', function () {
                createPOIPopUp(this);
                toggleActiveClickIcon(this, icon_default_config);
                displayBoardSinglePoi(poi, JKisio(this).data('icon-poi-url'));
            });
        }
        if (poi['coord'] !== undefined && Object.keys(poi['coord']).length > 1) {
            object_coord = poi['coord'];
            title = Translator.trans('proximity.map.pointType.poi');
            iconPOIUrl = parameters.urlImages + 'poi.png';
            if (poi.poi_type in Kisio.proximityMarkerPoiType) {
                title = Translator.trans(Kisio.proximityMarkerPoiType[poi.poi_type]['trad_key']);
                urlStr = Kisio.proximityMarkerPoiType[poi.poi_type]['url_img'];
                iconPOIUrl = urlStr.replace(regex, subst);
            }
            if (typeof poi.name !== 'undefined') {
                title = title + ' - ' + poi.name;
            }
            if (typeof poi.provider_name !== 'undefined') {
                title = title + ' - ' + poi.provider_name;
            }
            iconPOI = L.icon({
                iconUrl: iconPOIUrl,
                shadowUrl: iconURL,
                ...icon_default_config
            });
            markerIcon = L.marker([object_coord.lat, object_coord.lon], {
                id: poi.id,
                icon: iconPOI,
                title: title,
                keyboard: false,
                layerType: 'proximity',
                layer_section: ['proximity'],
                alt: title
            });
            JKisio(markerIcon).data('poi-object', poi).data('icon-poi-url', iconPOIUrl);
            Kisio.layerGroup.proximity.poiGroupLayer.addLayer(markerIcon);
            markerIcon.on('click', function () {
                freefloatingOnClick(this, L, icon_default_config, poi);
            });
        }

    }
    Kisio.layerGroup.proximity.poiGroupLayer.addTo(window.leaflet.map);

    // Disable tab naviguation for marker
    setTimeout(function () {
        JKisio(document).find('.marker-cluster').attr('tabindex', '-1');
    }, 1000);
};


/**
 * Create the HTML for the POI popUp
 * @param poi
 * @param iconPOIUrl
 * @returns {string}
 */
 export function createPOIPopUp(markerIcon) {
    let infoBattery;
    markerIcon.unbindPopup();
    let poi = JKisio(markerIcon).data('poi-object');
    let iconPOIUrl = JKisio(markerIcon).data('icon-poi-url');
    let type_product = (window.Kisio.type_product !== undefined) ? window.Kisio.type_product : 'standalone';
    let journeyButtons = '';
    // let favoriteBSS = getHTMLForBSSFavorite(poi);
    let infoDistance = getHTMLForDistanceObject(poi);
    if (type_product === 'full' && (poi.hasOwnProperty('poi') || poi.hasOwnProperty('coord'))) {
        let lat = poi.poi?.coord.lat
        let lon = poi.poi?.coord.lon

        if (poi.hasOwnProperty('coord')) {
            lat = poi.coord.lat;
            lon = poi.coord.lon;
        }
        
        journeyButtons = new ButtonDepartureArrival({
            lat: lat,
            lng: lon,
            address: poi.name
        }).buildHtml();
    }
    let name = poi.name;
    let address = '';
    if (poi.poi !== undefined && poi.poi.address !== undefined && poi.poi.address.label !== undefined) {
        address = poi.poi.address.label;
    }
    let additionnal_content_bss = '';
    if (poi.poi_type === "amenity:bicycle_rental" && poi.poi !== undefined && poi.poi.stands !== undefined && poi.poi.stands !== null && (poi.poi.stands.available_bikes !== undefined || poi.poi.stands.available_places === undefined)) {
        if (poi.poi.stands.available_bikes !== undefined) {
            additionnal_content_bss = '<br /><span>' + Translator.trans('proximity.data.bss.available_bikes') + '<img src="'+Kisio.realtime_data_picto+'" /> ' + poi.poi.stands.available_bikes  +'</span>';
        }
        if (poi.poi.stands.available_places !== undefined) {
            additionnal_content_bss += '<br /><span>' + Translator.trans('proximity.data.bss.available_places') + '<img src="'+Kisio.realtime_data_picto+'" /> ' + poi.poi.stands.available_places  +'</span>';
        }
    }
    let additionnal_content_PPlusR = '';
    if (poi.poi_type === "amenity:parking" && poi.poi !== undefined && poi.poi.car_park !== undefined && poi.poi.car_park !== null && (poi.poi.car_park.available !== undefined || poi.poi.car_park.total_places === undefined)) {
        if (poi.poi.car_park.available !== undefined) {
            additionnal_content_PPlusR = '<br /><span>' + Translator.trans('proximity.data.car_park.available') + '<img src="'+Kisio.realtime_data_picto+'" /> ' + poi.poi.car_park.available  +'</span>';
        }
        if (poi.poi.car_park.total_places !== undefined) {
            additionnal_content_PPlusR += '<br /><span>' + Translator.trans('proximity.data.car_park.total_places') + '<img src="'+Kisio.realtime_data_picto+'" /> ' + poi.poi.car_park.total_places  +'</span>';
        }
    }

    //This test is to see if there is a special trad key for the client
    if (
        typeof Kisio.proximityMarkerPoiType[poi.poi_type].trad_key !== 'undefined'
        && Kisio.proximityMarkerPoiType[poi.poi_type].trad_key !== ''
        && Kisio.proximityMarkerPoiType[poi.poi_type].trad_key.indexOf('proximity.') < 0
    ) {
        name = Kisio.proximityMarkerPoiType[poi.poi_type].trad_key+ ' : '+name;
    }
    let queryShowStops = '';
    if (JKisio('#proximity-show-hide-stops button').hasClass('hideStops')) {
        queryShowStops = '&proximity_search[show_stops]=true';
    } else if (JKisio('#proximity-show-hide-stops button').hasClass('showStops')) {
        queryShowStops = '&proximity_search[show_stops]=false';
    }
    // let proximityButton = createProximityButton(poi.poi.coord, name, autocompleteHidden, queryShowStops);
    if(poi.provider_name != "Get Around" && typeof poi.battery !== 'undefined'){
        let picto = "";

        switch(true){
            case poi.battery == 0 :
                picto = 'picto/battery-0.svg';
                break;
            case poi.battery <= 25 :
                picto = 'picto/battery-25.svg';
                break;
            case poi.battery <= 50 :
                picto = 'picto/battery-50.svg';
                break;
            case poi.battery <= 75 :
                picto = 'picto/battery-75.svg';
                break;
            case poi.battery <= 100 :
                picto = 'picto/battery-100.svg';
                break;
        }

        infoBattery = '<div class="proximity-poi-distance proximity-poi-freefloating"><span><img src="'+(parameters.urlImages + picto)+'" /> ' + poi.battery + ' %</span></div>';
    }else {
        infoBattery = '';
    }
    
    let popupContent = "";
    if (poi.poi_type === "amenity:bicycle_rental" || poi.poi_type === "amenity:parking") {
        popupContent = '<div id="leaflet-popup" class="proximity-poi-popup">' +
                '<div id="leaflet-popup-header">' +
                    '<div class="leaflet-popup-header-img">' +
                        '<img src="' + iconPOIUrl + '" width="15" height="15" alt="' + name + '" />' +
                    '</div>' +
                    '<span>' + name + '</span>' +
                '</div>' +
                '<div id="leaflet-popup-content" class="clearfix">' +
                    '<div class="proximity-poi-distance-container">' +
                    Translator.trans('places_nearby.popup.at') + " " + poi.distance + "m" +
                    '</div>'
                '</div>' +
            '</div>';
    }else {
        popupContent = '<div id="leaflet-popup" class="proximity-poi-popup">' +
            '<div id="leaflet-popup-header">' +
            '<div class="leaflet-popup-header-img">' +
            '<img src="' + iconPOIUrl + '" width="15" height="15" alt="' + name + '" />' +
            '</div>' +
            '<span>' + Translator.trans(Kisio.proximityMarkerPoiType[poi.poi_type]['trad_key']) + '</span>' +
            '</div>' +
            '<div id="leaflet-popup-content" class="clearfix">' +
            '<div class="proximity-poi-name-container">' +
                name +
            '</div>' + journeyButtons +
            '</div>' +
            '</div>';
    }
    markerIcon.bindPopup(popupContent, {offset: (5, 5), closeButton: false}).openPopup();
};


/**
 * Create the HTML for the POI popUp FreeFloatings
 * @param poi
 * @param iconPOIUrl
 * @returns {string}
 */
 export function createPOIPopUpFreeFloatings(markerIcon, addr) {
    markerIcon.unbindPopup();
    let poi = JKisio(markerIcon).data('poi-object');
    let iconPOIUrl = JKisio(markerIcon).data('icon-poi-url');
    let autocompleteHidden = poi.id;
    let latlng = encodeURIComponent(JSON.stringify(poi.coord));
    let type_product = (window.Kisio.type_product !== 'undefined') ? window.Kisio.type_product : 'standalone';
    let journeyButtons = '';
    let favoriteBSS = getHTMLForBSSFavorite(poi);
    let infoDistance = getHTMLForDistanceObject(poi);
    let coordonnees = Math.round(poi.coord.lon * 1000000) / 1000000 + ';' + Math.round(poi.coord.lat * 1000000) / 1000000;
    let address = '';
    if (poi.poi_type === "SCOOTER" || poi.poi_type === "MOTORSCOOTER" || poi.poi_type === "CAR" ) {
        if(poi.provider_name == "Get Around")
            address = '<img src="' + iconPOIUrl + '" width="24" height="24" alt="'+poi.provider_name+' icon" /> ' + poi.provider_name  +'</span>';
        else {
            if (poi.hasOwnProperty('public_id')) {
                address = poi.provider_name + ' ' + poi.public_id;
            } else {
                address = poi.provider_name;
            }

        }
    }

    let header_adress;
    let infoBattery;
    if (typeof addr.display_name !== 'undefined') {
        header_adress = addr.display_name;
    }else{
        header_adress = coordonnees;
    }

    if (type_product === 'full') {
        journeyButtons = new ButtonDepartureArrival({}).buildHtml(); 
    }

    //This test is to see if there is a special trad key for the client
    if (typeof Kisio.proximityMarkerPoiType[poi.poi_type].trad_key !== 'undefined' && Kisio.proximityMarkerPoiType[poi.poi_type].trad_key.indexOf('proximity.') < 0) {
        coordonnees = Kisio.proximityMarkerPoiType[poi.poi_type].trad_key+ ' : '+coordonnees;
    }
    let queryShowStops = '';
    if (JKisio('#proximity-show-hide-stops button').hasClass('hideStops')) {
        queryShowStops = '&proximity_search[show_stops]=true';
    } else if (JKisio('#proximity-show-hide-stops button').hasClass('showStops')) {
        queryShowStops = '&proximity_search[show_stops]=false';
    }
    // let proximityButton = createProximityButton(poi.coord, coordonnees, autocompleteHidden, queryShowStops);
    if(poi.provider_name != "Get Around"){
        if (poi.hasOwnProperty('battery')) {
            infoBattery = '<div class="proximity-poi-distance"><img src="' + (parameters.urlImages + 'modes/Groupe 120.svg') + '" alt="Battery icon" /><span>' + poi.battery + ' kms</span></div>';
        } else {
            infoBattery = '<div class="proximity-poi-distance"><img src="' + (parameters.urlImages + 'modes/Groupe 120.svg') + '" alt="Battery icon" /><span> 0 kms</span></div>';    
        }
    }else {
        infoBattery = '';
    }

    let popupContent = '<div id="leaflet-popup" class="proximity-poi-popup">' +
        '<div id="leaflet-popup-header">' +
        '<img src="' + iconPOIUrl + '" width="24" height="24" alt="' + header_adress + '_icon" />' +
        '<span>' + header_adress + '</span>' +
        '</div>' +
        '<div id="leaflet-popup-content" class="clearfix">' +
        '<div class="proximity-poi-info-container">' +
        '<div class="proximity-poi-info-left-side">' +
        '<div class="proximity-poi-address">' + address + '</div>' +
        '</div>' +
        '<div class="proximity-poi-info-right-side">' +
        // favoriteBSS +
        infoDistance +
        infoBattery +
        '</div>' +
        '</div>' + journeyButtons + //proximityButton +
        '</div>' +
        '</div>';
    markerIcon.bindPopup(popupContent, {closeButton: false}).openPopup();
};

const toggleActiveClickIcon = (that, config) => {
    that.setIcon(L.divIcon({
        iconSize: [47, 47],
        iconAnchor: [16, 35],
        html: activeIconHtml(that),
        className: 'active-poi-marker'
    }));

    that.getPopup().on('remove', function() {
        that?.setIcon(L.icon({
            iconUrl: JKisio(that).data('icon-poi-url'),
            shadowUrl: parameters.urlImages + 'pin-proximity.svg',
            ...config
        }));
    });
}

export const activeIconHtml = (that) => {
    return (
        `<svg class="active-poi-marker-pin" width="100%" height="100%" viewBox="0 0 65 80" fill="#00AECE" stroke="#00AECE" xmlns="http://www.w3.org/2000/svg">
            <path d="M62.5 32.5C62.5 40.3327 57.3508 46.659 50.264 53.8513C49.318 54.8114 48.3391 55.785 47.3423 56.7764C42.1072 61.9831 36.3782 67.681 32.3194 74.5008C27.9644 68.059 22.6502 62.6661 17.8174 57.7615C16.4474 56.3712 15.1161 55.0201 13.8563 53.6955C7.14399 46.6382 2.5 40.4031 2.5 32.5C2.5 15.9315 15.9315 2.5 32.5 2.5C49.0685 2.5 62.5 15.9315 62.5 32.5Z" stroke-width="5"/>
        </svg>
        <img class="active-poi-marker-icon" src="${JKisio(that).data('icon-poi-url')}" alt="SVG Image" />
    `);
}

const freefloatingOnClick = (markerIcon, L, icon_default_config, poi) => {
    let type_product = (window.Kisio.type_product !== 'undefined') ? window.Kisio.type_product : 'standalone';
    let coordonnees = Math.round(poi.coord.lon * 1000000) / 1000000 + ';' + Math.round(poi.coord.lat * 1000000) / 1000000;

    JKisio.ajax({
        url: Routing.generate('coords', {coords: coordonnees, type_product: type_product}),
        dataType: "json",
        async: false,
        success: function (result) {
            createPOIPopUpFreeFloatings(markerIcon, result);
            toggleActiveClickIcon(markerIcon, icon_default_config);
            displayBoardSinglePoi(poi, JKisio(markerIcon).data('icon-poi-url'), result);
        }
    })
}