import { getModePicto, getLinePicto } from 'pnp_core_bundle/plugnplay/customize_client';

export const desktopBreakPoint = 600;

/**
 * Create the HTML for the stop_point PopUp
 * @param markerStop
 */
 export function createStopPointPopUp(markerStop, stop, result, listModes, lines) {
    let stopAreaLabel = '';
    if (stop.stop_point !== undefined && stop.stop_point.stop_area !== undefined && stop.stop_point.stop_area.label !== undefined) {
        stopAreaLabel = stop.stop_point.stop_area.label;
    }
    let stopAreaId = '';
    if (stop.stop_point !== undefined && stop.stop_point.stop_area !== undefined && stop.stop_point.stop_area.id !== undefined) {
        stopAreaId = stop.stop_point.stop_area.id;
    }
    let name = stop.name;
    let classNoContent = ' class="clearfix"';
    let queryShowStops = '';
    if (JKisio('#proximity-show-hide-stops button').hasClass('hideStops')) {
        queryShowStops = '&proximity_search[show_stops]=true';
    } else if (JKisio('#proximity-show-hide-stops button').hasClass('showStops')) {
        queryShowStops = '&proximity_search[show_stops]=false';
    }
    let htmlHeader = '<span>' + name + '</span>';
    let htmlContent = '';
    let level_severity = '';

    let arrayOfDisruption = [];
    if (typeof result.disruptions !== "undefined") {
        let disruptions = result.disruptions;
        disruptions.forEach(function (d) {
            let severity = d.severity.effect;
            switch (severity) {
                case 'NO_SERVICE':
                    level_severity = 'disrupt';
                    break;
                case 'SIGNIFICANT_DELAYS':
                case 'REDUCED_SERVICE':
                case 'DETOUR':
                case 'ADDITIONAL_SERVICE':
                case 'MODIFIED_SERVICE':
                case 'UNKNOWN_EFFECT':
                case 'STOP_MOVED':
                    level_severity = 'warning';
                    break;
                case 'OTHER_EFFECT':
                default:
                    level_severity = 'information';
                    break;
            }
            arrayOfDisruption[d.impacted_objects[0].pt_object.id] = {
                'cause' : d.cause,
                'image': '/bundles/canaltppnpcore/images/disruptions/'+level_severity+'.svg'
            }
        });
    }

    let listLines = [];
    listModes.forEach((mode) => {
        let linesContent = '';

        lines.forEach(function (l) {
            if (mode.id == l.commercial_mode.id) {
                let idLine = l.id;
                if (listLines.indexOf(idLine) === -1) {
                    listLines.push(idLine);
                    linesContent += getLinePicto(l, arrayOfDisruption, 'lines_in_popup');
                }
            }
        });

        if (linesContent !== '') {
            htmlContent += '<div class="proximity-poi-mode-row">';
            htmlContent += `<div class="proximity-poi-mode-row-mode">${getModePicto(mode.id)}</div>`;
            htmlContent += `<div class="proximity-poi-mode-row-lines">${linesContent}</div>`
            htmlContent += '</div>';
        }

    })

    let popupContent = '<div id="leaflet-popup" class="proximity-poi-popup">' +
        '<div id="leaflet-popup-header"' + classNoContent + '>' + htmlHeader + '</div>' +
        '<div id="leaflet-popup-content"' + classNoContent + '>' +
        '<div class="proximity-poi-info-container proximity-poi-info-column-container">' + htmlContent
        '</div>';

    markerStop.bindPopup(popupContent, {closeButton: false}).openPopup();

};
