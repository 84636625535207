import {MarkerClusterGroup, LayerGroup} from 'pnp_core_bundle/lib/leaflet.markercluster.js';
import { getUrlParameter } from './core/manageUrl';
import { launchProximitySearch, updateProximityRequest, checkAutocompletesValues, addMarker } from './core/manageProximity';
import { hideProximityResultContent, showProximityResultContent } from './core/manageResults';
import { disableEventNewSearch, hideNewSearchBtn } from './components/newSearchBtn';
import { closeAutocomplete } from './components/autocomplete/mobileResizeAutocomplete';

const icon_default_config = {
    iconSize: 16,
    iconAnchor: [4, 30],
    shadowSize: 37,
    shadowAnchor: [15, 35],
    popupAnchor: [4, -25],
    className: 'proximity-poi-icon'
};

export function initProximity() {
    const modelProximity = Kisio.loadBoardEvents.models.libModels.extend({
        /**
         * Initialize Proximity
         */
        initializeProximity: function () {
            if (window.Kisio.type_product === 'standalone') {
                JKisio('#proximity_clear').hide();
            }

            Kisio.proximity = {
                show_stop_area: true
            }

            L.MarkerCluster.addInitHook(function () {
                this.options.keyboard = false;
            });

            if (window.Kisio.type_product !== 'mg' && Kisio.proximity_clustering_activated !== undefined && Kisio.proximity_clustering_activated) {
                Kisio.layerGroup.proximity.poiGroupLayer = new  MarkerClusterGroup({
                    maxClusterRadius: Kisio.proximity_clustering_maxClusterRadius
                });
                Kisio.layerGroup.proximity.stopPointGroupLayer = new MarkerClusterGroup({
                    maxClusterRadius: Kisio.proximity_clustering_maxClusterRadius
                });
            }

            //Desactivate click to avoid recalculation of top board
            JKisio(document).on('click', '#proximity_title', function () {
                return false;
            });

            //Modify behavior for /full/proximity
            if (JKisio('#show_proximity').val() === '1') {
                JKisio('#proximity_content').show();
                JKisio('#board_title_container, #journey_content, #board_footer').hide();
                JKisio(".autocomplete-search-icon, #proximity-search-form .locate-position-proximity").show();
            }

            //Product full and proximity option activated
            if (JKisio('#footer_proximity').length > 0 && !JKisio('#proximity_content').is(':visible')) {
                JKisio("#footer_proximity, #proximity-search-form .locate-position-proximity").click(function (e) {
                    e.preventDefault();
                    document.body.dispatchEvent(new CustomEvent('open_proximity_event'));
                });
            }

            //Product standalone
            if (Kisio.type_product == "standalone" && JKisio('#proximity_content').is(':visible')) {
                if(!window.leaflet.map){
                    document.body.dispatchEvent(new CustomEvent('initialize_map'));
                }

                // JKisio('#board').addClass('proximity-standalone');

                document.body.dispatchEvent(new CustomEvent('open_proximity_event'));
            }


            //On poi list, launch actions on buttons Departure/Arrival/Geoloc
            JKisio(document).on('click', ".prox-departure-btn, .prox-arrival-btn, .prox-recenter-btn", function () {
                let fieldType = JKisio(this).attr('data-type');
                let address = JKisio(this).attr('data-address');
                let latlng = JKisio(this).attr('data-coord');
                let object_coord = latlng.split(';');
                let coords = {lat: object_coord[0], lng: object_coord[1]};
                let type = 'map';
                let autocompleteHiddenValue = '';
                if (JKisio(this).attr('data-autocompleteHidden') !== undefined) {
                    type = 'prox';
                    autocompleteHiddenValue = JKisio(this).attr('data-autocompleteHidden');
                }
                let params = {
                    fieldType: fieldType,
                    address: address,
                    latlng: coords,
                    type: type,
                    autocompleteHiddenValue: autocompleteHiddenValue
                };
                //In case, the user is in the favorite section, hide it to show other section
                if (JKisio('#favorites_content').length > 0) {
                    JKisio('#favorites_content').hide();
                }
            });
        },
        /**
         * Manage change
         */
        manageChange: function () {
            let _this = this;

            JKisio(document).on('change, keyup', '#proximity_search_uri_autocomplete-hidden', function () {
                console.log(JKisio('#proximity_search_uri_autocomplete-hidden').val());
            });

            /*
            * Prevent Submitting Form by traditionnal way
            */
            JKisio(document).on('submit', '#ctp-proximityForm, #ctp-proximityPoiForm', function (event) {
                event.preventDefault();
                checkAutocompletesValues();
            });

            // Change distance
            JKisio(document).on('click', '#proximity-search-time-input input', function () {
                JKisio('#proximity_search_uri .popup').remove();
                if (checkAutocompletesValues()) {
                    JKisio('#loading-box').css("visibility", "visible");
                    let distance = JKisio(this).val();
                    let distanceId = JKisio(this).attr('id');
                    if (JKisio('#ctp-proximityPoiForm').length > 0) {
                        JKisio('#ctp-proximityPoiForm #proximity_search_distance').val(distance);
                    }
                    const queryString = decodeURIComponent(window.location.search);
                    const showStops = getUrlParameter(queryString, 'proximity_search[show_stops]');
                    if (JKisio('#proximity-show-hide-stops button').hasClass('hideStops')) {
                        showStops = 'true';
                    } else if (JKisio('#proximity-show-hide-stops button').hasClass('showStops')) {
                        showStops = 'false';
                    }
                    document.body.dispatchEvent(new CustomEvent('hide_layers_section_event', {detail: {section: 'proximity', clean_data:true}}));
                    setTimeout(function () {
                        const autocompleteInput = JKisio('#proximity_search_uri_autocomplete');
                        const queryParams = {
                            proximity_search: {
                                coords: autocompleteInput.attr('data-coord'),
                                uri: {
                                    "autocomplete": autocompleteInput.val(),
                                    "autocomplete-hidden": JKisio('#proximity_search_uri_autocomplete-hidden').val()
                                }
                            }
                        };
                        addMarker(JKisio('#proximity_search_uri_autocomplete').attr('data-coord'));
                        // drawCircle(JKisio('#proximity_search_uri_autocomplete').attr('data-coord'), distance);
                        updateProximityRequest(showStops, queryParams);
                        JKisio('#loading-box').css("visibility", "hidden");
                    },300);
                }
            });
        },
    });
    let prox = new modelProximity();
    prox.initializeProximity();
}

// Click on category title
JKisio(document).on('click', '.ctp-proxi-accordion legend', function (e) {
    if (!JKisio(e.target).hasClass('chk_all')) {
        if (JKisio(this).next('div').is(':visible')) {
            JKisio(this).attr('aria-expanded', 'false');
            JKisio(this).find('i').removeClass('ikisio-arrow-drop-down').addClass('ikisio-arrow-drop-up');
            JKisio(this).next('div').hide().promise().done(function () {
                JKisio('#proximity-results').css({'height': 'auto'});
                window.proximitySimpleBar.recalculate();
            });
        } else {
            let __this = this;
            JKisio('#ctp-proximity-poi .ctp-proxi-accordion fieldset > div').hide().promise().then(function () {
                JKisio(__this).attr('aria-expanded', 'true');
                JKisio('#ctp-proximity-poi .ctp-proxi-accordion legend i').removeClass('ikisio-arrow-drop-down').addClass('ikisio-arrow-drop-up');
                JKisio(__this).find('i').removeClass('ikisio-arrow-drop-up').addClass('ikisio-arrow-drop-down');
                JKisio(__this).next('div').show().promise().then(function () {
                    JKisio('#proximity-results').css({'height': 'auto'});
                    window.proximitySimpleBar.recalculate();
                });
            });
        }
    }
});

/*
* Add marker when click on an autocomplete element
*/
document.body.addEventListener('#proximity_search_uri_autocomplete-item-selected', function () {
    disableEventNewSearch();
    hideNewSearchBtn();
    proximityAutocompleteSearch();
    closeAutocomplete();
});

/**
 * Deal with Enter key pressed
 */
JKisio(document).on('keyup', '#proximity_search_uri_autocomplete', function (e) {
    if (!['Enter', 'NumpadEnter'].includes(e.key)) {
        return;
    }

    if (!checkAutocompletesValues()) {
        return;
    }

    e.preventDefault();

    setTimeout(function () {
        disableEventNewSearch();
        hideNewSearchBtn();
        proximityAutocompleteSearch();
    }, 150);
});

const proximityAutocompleteSearch = () => {
    disableEventNewSearch();
    hideNewSearchBtn();
    launchProximitySearch();
}

/**
 * Click on button to Show addresses or Show Preferences
 */
JKisio(document).on('click', '#show_poi_list', function () {
    if (JKisio('.poi_list').is(':visible')) {
        JKisio('#show_poi_list').html(Translator.trans('proximity.result.show_poi_list'));
        showProximityResultContent('.poi_list', function () {
            JKisio('#ctp-poi-bloc .ctp-proxi-accordion:first-child fieldset legend').focus();
        });
    } else {
        JKisio('#show_poi_list').html(Translator.trans('proximity.result.show_category_list'));
        hideProximityResultContent('#ctp-proximity-poi', function () {
            JKisio('#display_poi > div:first-child .ctp-list-point > fieldset:first-child legend').focus();
        });
    }
});

/**
 * Fold and Unfold list of Poi
 */
JKisio(document).on('click', '.poi_list .ctp-list-point legend', function () {
    if (JKisio(this).next('ul').is(':visible')) {
        JKisio(this).attr('aria-expanded', 'false');
        JKisio(this).find('i').removeClass('ikisio-arrow-drop-down').addClass('ikisio-arrow-drop-up');
        JKisio(this).next('ul').hide().promise().done();
    } else {
        let __this = this;
        JKisio('.poi_list fieldset > ul').hide().promise().then(function () {
            JKisio('.poi_list legend .ikisio').removeClass('ikisio-arrow-drop-down').addClass('ikisio-arrow-drop-up');
            JKisio(__this).attr('aria-expanded', 'true');
            JKisio(__this).find('i').removeClass('ikisio-arrow-drop-up').addClass('ikisio-arrow-drop-down');
            JKisio(__this).next('ul').show().promise().done();
        });
    }

});


JKisio(document).on('click','.locate-position-proximity', function(e){
    navigator.geolocation.getCurrentPosition(
        function(position) { },
        function(positionError) {
            if(positionError.code == 1) {
                window.Kisio.autocomplete_config.geolocation = 'denied';
            }else{
                window.Kisio.autocomplete_config.geolocation = 'granted'
            }
        },
    );
    window.leaflet.map.locate({setView: true, maxZoom: Kisio.map_config.max_zoom});
    

    let fieldType = JKisio(this).attr('data-type');
    if (fieldType === 'from') {
        window.leaflet.map.on('locationfound', locationFound);
        JKisio('#search-from-autocomplete-clear').show();
    }

    //If there is results, show new search section
    if (JKisio('.kisio-result').is(':visible')) {
        JKisio('.kisio-result').hide();
        JKisio('#journey-form-new-search').show();
    }
});

JKisio(document).on('keyup', '.locate-position-proximity', (event) => {
    if (['Enter', 'NumpadEnter'].includes(event.key)) {
        JKisio('.locate-position-proximity').click();
    }
});

function locationFound(e) {
    if (!e.latlng) {
        return;
    }

    const coords = e.latlng.lng+';'+e.latlng.lat;
    const inverted_coords = e.latlng.lat+';'+e.latlng.lng;
    
    addMarker(coords);
    
    JKisio('#proximity_search_uri_autocomplete').val(Translator.trans('journey.form.geolocalisation.field_value'));
    JKisio('#proximity_search_uri_autocomplete').attr('data-coord', inverted_coords);
    JKisio('#proximity_search_uri_autocomplete-hidden').val(coords);
    launchProximitySearch();
    window.leaflet.map.flyTo([e.latlng.lat,e.latlng.lng], window.Kisio?.map_config.max_zoom);
};