import { openFullBoard, isFullBoardOpen, eventListenerBack } from "..";
import opening_hours from "../../../modules/opening_hours/opening_hours.min.js";
import { parameters } from "../../../parameters";
import { BookmarkStarElementBuilder } from "bookmark_bundle/components/bookmark-star/bookmark-star-element";
import { NavitiaBuilder } from "bookmark_bundle/builder/navitia/navitia-builder";
import { ButtonDepartureArrival } from "journey_bundle/modules/buttonDepartureArrival/builder";
import { detailsCategoriesConst } from "../../categories/detailsCategories/constants";
import { activeIconHtml, createPOIPopUp, icon_default_config } from "../../map/marker";
import SimpleBar from "pnp_core_bundle/simplebar/simplebar";

export const displayBoardSinglePoi = (poi, iconPOIUrl, address) => {
    if (window.getComputedStyle(document.querySelector('#mobile-minimizer')).display !== 'none') {
        document.querySelector('#board_container').classList.add('board_expanded_proximity');
    }

    if (isFullBoardOpen()) {
        openBoardPoi(
            orderPlaceObject([poi]),
            Translator.trans(Kisio.proximityMarkerPoiType[poi.poi_type]['trad_key']),
            iconPOIUrl,
            address,
            true,
            true
        );
    } else {
        openBoardPoi(
            orderPlaceObject([poi]),
            Translator.trans(Kisio.proximityMarkerPoiType[poi.poi_type]['trad_key']),
            iconPOIUrl,
            address,
            true
        );
    }

    openFullBoard();
    JKisio("#ctp-proximity-stoparea").show();
}

export const displayBoardWithPoi = (data, categoryName, categoryImg, withHistory = false) => {
    let objectPlaceOrdered = orderPlaceObject(data);

    openFullBoard();
    JKisio("#ctp-proximity-stoparea").show();

    openBoardPoi(objectPlaceOrdered, categoryName, categoryImg, null, false, withHistory);
}

export const updateBoardWithPoi = (data) => {
    let objectPlaceOrdered = orderPlaceObject(data);

    updateBoardPoi(objectPlaceOrdered);
}

const openBoardPoi = (
    objectPlaceOrdered,
    categoryName,
    categoryImg,
    address = null,
    isSinglePoi = false,
    withHistory = false
) => {
    let contentHtml = generateContentHtml(objectPlaceOrdered, address, isSinglePoi);

    let html = `<div class="proximity_stoparea_container">`;

    if (withHistory) {
        html += `<div class="back" data-type=${isSinglePoi ? "single" : "full-result"} ><i role="presentation" class="ikisio ikisio-back" aria-hidden="true"></i> ${Translator.trans('proximity.form.back')}</div>`;
    }

    html +=        `<div class="proximity_stoparea_title">
                        <img src="${categoryImg}" alt="${categoryName}" />
                        ${categoryName}
                    </div>
                    <div class="proximity_poi_content">
                        ${contentHtml}
                    </div>
                </div>`;

    JKisio("#ctp-proximity-filters").hide();

    let element = document.querySelector("#proximity_stoparea_content");

    if (!isSinglePoi) {
        let history = document.querySelector('#board-history');
        
        if (history == null) {
            history = document.createElement('div');
            history.innerHTML = html;
            history.setAttribute('id', 'board-history');
            history.setAttribute('tabindex', '-1');
            element.parentElement.appendChild(history);
        }
        
        history.innerHTML = html;
    }

    element.innerHTML = html;

    eventToggleProximityContainer();
    eventHoverBoardToMap();
    simplebarManagement(".proximity_poi_content");

    if (withHistory == true) {
        eventListenerBack();
    }
}

const simplebarManagement = (elementIdentifiant) => {
    let content = document.querySelector(elementIdentifiant);

    if (content.SimpleBar) {
        content.SimpleBar.unMount();
    }
    new SimpleBar(content);
}

const updateBoardPoi = (objectPlaceOrdered) => {
    let element = document.querySelector("#proximity_stoparea_content .proximity_poi_content");

    let categoryImg = document.querySelector(detailsCategoriesConst.titleActive +" img").src;
    let categoryName = document.querySelector(detailsCategoriesConst.titleActive).getAttribute("data-controls");

    let sliderMore = document.querySelector("#ctp-proxi-more");
    if (!sliderMore.classList.contains('active_category')) {

        categoryImg = document.querySelector("#ctp-proximityPoiForm .active_category img").src;
        categoryName = document.querySelector("#ctp-proximityPoiForm .active_category").getAttribute("data-name");
    }

    if (element == null) {
        openFullBoard();
        JKisio("#ctp-proximity-stoparea").show();
        openBoardPoi(objectPlaceOrdered, categoryName, categoryImg, null, false, false);
        return;
    }

    let contentHtml = generateContentHtml(objectPlaceOrdered);

    let html = `
                <img src="${categoryImg}" alt="${categoryName}" />
                ${categoryName}
                `;
    
    let container = document.querySelector('.proximity_stoparea_title');

    container.innerHTML = html;

    element.innerHTML = contentHtml;
    eventToggleProximityContainer();
    eventHoverBoardToMap();
    simplebarManagement(".proximity_poi_content");
}

const generateContentHtml = (objectPlaceOrdered, address = null, isSinglePoi = false) => {
    let html = "";

    objectPlaceOrdered?.forEach((category) => {
        if (category.poi_type === undefined) {
            return;
        }

        html += `<div class="proximity_category_container" data-category="${category.poi_type}">
                    <div class="proximity_category_title">
                        ${ Kisio.proximityMarkerPoiType[category.poi_type] ? Translator.trans(Kisio.proximityMarkerPoiType[category.poi_type]['trad_key']) : category.poi_type}
                        <span></span>
                    </div>
                    <div class="proximity_category_content">
                `;

        if (category.type === 'places_nearby') {
            category.places.forEach((place) => {
                html += generateContentHtmlPlaceNearby(place)
            })
        }else if (category.type === 'free_floatings') {
            category.places.forEach((freeFloating) => {
                html += generateContentHtmlFreeFloating(freeFloating, address, isSinglePoi)
            })
        }

        html += `</div></div>`
    });

    return html;
}

const orderPlaceObject = (data) => {
    if (!data) {
        return null;
    }

    if (data.hasOwnProperty('response')) {
        data = data.response;
    }

    let res = [];

    data.forEach((places) => {
        let placesOrder = [];
        let type = 'places_nearby';
        if (places.hasOwnProperty("places_nearby")) {
            places.places_nearby.forEach((place) => {
                placesOrder.push(place);
            })
        }else if (places.hasOwnProperty("free_floatings")) {
            type = 'free_floatings';
            places.free_floatings.forEach((place) => {
                placesOrder.push(place);
            })
        }else if (places.hasOwnProperty("poi")) {
            placesOrder.push(places);
        }else{
            type = 'free_floatings';
            placesOrder.push(places);
        }
        placesOrder.sort((a, b) => a.distance - b.distance);

        for (let i = 0; i < res.length; i++) {
            if (res[i].category === places.category) {
              res[i].places = [...res[i].places, ...placesOrder]
              return;
            }
        }

        res.push({
            'category': places.category,
            'type': type,
            'places': placesOrder,
            'poi_type':  places.poi_type
        });
    })

    return res;
}

const generateContentHtmlPlaceNearby = (place) => {
    
    let html = '';

    let regex = /(asset->)/gm;
    let subst = "/";

    let navitiaBuilder = new NavitiaBuilder();

    let urlStr = Kisio.proximityMarkerPoiType[place.poi_type] ? Kisio.proximityMarkerPoiType[place.poi_type]['url_img'] : "";

    let iconPOIUrl = urlStr.replace(regex, subst);

    if (place.hasOwnProperty('poi') == false) {
        place.poi = place.stop_point;
    }

    let star = new BookmarkStarElementBuilder({
        type: "poi",
        params: navitiaBuilder.poi().setPoi(
            place.id,
            place.poi_type,
            iconPOIUrl,
            place.poi.coord.lat,
            place.poi.coord.lon,
            place.poi.label,
            place.poi.address.label,
        ).getNavitiaObject()
    }).
    buildHtml(); // tu peux aussi recuperer l'element avec build

    if (place.hasOwnProperty('poi') == false) {
        place.poi = place.stop_area;
    }

    let header_address;
    if (typeof place.name !== 'undefined') {
        header_address = place.name;
    }else{
        header_address = Math.round(place.poi.coord.lon * 1000000) / 1000000 + ';' + Math.round(place.poi.coord.lat * 1000000) / 1000000;;
    }

    html += `<div class="poi_container" data-poi="${place.id}" tabindex="0">
                <div class="poi_title_container">
                    <span class="poi_title">${place.name}</span>`

                if (place.poi_type == "amenity:bicycle_rental" || place.poi_type == "amenity:parking") {
                    html += `<div class="poi_favorite">${star}</div>`;
                }

    html +=    `</div>
                <div class="poi_content_container">
                    <div class="poi_content_container_info">
                        <div class="poi_content_distance">À ${place.distance}m</div>
                        <div class="poi_content_address_name">${place.poi.hasOwnProperty("address") ? place.poi.address.label : ""}</div>
                        <div class="poi_content_postal_code">
                            ${place.poi.hasOwnProperty("properties") ? 
                                place.poi.properties["addr:postcode"] = place.poi.properties["addr:postcode"] || '' : ""} 
                            ${place.poi.hasOwnProperty("address") ? 
                                (place.poi.address.hasOwnProperty('administrative_regions') ? place.poi.address.administrative_regions[0].name : "") : ""}
                        </div>
                        `;
                        if (place.poi.hasOwnProperty("properties")) {
                            if (place.poi.properties['opening_hours']) {
                                html += displayOpeningHours(place.poi.properties['opening_hours']);
                            }
                        }

    html +=            `</div>`
                        if (place.poi.hasOwnProperty('stands')) {
                            html += `<div class="poi_content_container_half_infos">`
                                if (place.poi_type === "amenity:bicycle_rental" && place.poi !== undefined && place.poi.stands !== undefined && place.poi.stands !== null && (place.poi.stands.available_bikes !== undefined || place.poi.stands.available_places === undefined)) {
                                    // Available place for bike
                                    if (place.poi.stands.available_places !== undefined) {
                                        let availability = calculavailabilityByThird(place.poi.stands.available_places, place.poi.stands.total_stands)
                                        html += `<div class="places_container ${availability}_places">
                                                    <div class="icon">
                                                    <i class="ikisio ikisio-parking"></i>
                                                    </div>
                                                    <img class="realtime" src="${Kisio.realtime_data_picto_svg}" alt="Realtime picto" />
                                                    ${place.poi.stands.available_places} ${Translator.trans('proximity.data.bss.available_places')}
                                                </div>`
                                    }
                                    // Available bike
                                    if (place.poi.stands.available_bikes !== undefined) {
                                        let availability = calculavailabilityByThird(place.poi.stands.available_bikes, place.poi.stands.total_stands)
                                        html += `<div class="places_container ${availability}_places">
                                                    <div class="icon">
                                                    <i class="ikisio ikisio-big-bicycle"></i>
                                                    </div>
                                                    <img class="realtime" src="${Kisio.realtime_data_picto_svg}" alt="Realtime picto" /> 
                                                    ${place.poi.stands.available_bikes} ${Translator.trans('proximity.data.bss.available_bikes')}
                                                </div>`
                                    }
                                }
                                html += `</div>`
                        }
                        if (place.poi.hasOwnProperty('car_park')) {
                            html += `<div class="poi_content_container_half_infos">`
                            if (place.poi.poi_type.id === "poi_type:amenity:parking" && place.poi !== undefined && place.poi.car_park !== undefined) {
                                    // Available place
                                    if (place.poi.car_park.available !== undefined) {
                                        let availability = calculavailabilityByThird(place.poi.car_park.available, (place.poi.properties.capacity - place.poi.properties['capacity:disabled']))
                                        html += `<div class="places_container ${availability}_places">
                                                    <div class="icon">
                                                        <i class="ikisio ikisio-parking"></i>
                                                    </div>
                                                    <img class="realtime" src="${Kisio.realtime_data_picto_svg}" alt="Realtime picto" /> 
                                                    ${place.poi.car_park.available} ${Translator.trans('proximity.data.bss.available_places')}
                                                </div>`
                                    }
                                    // Available place PRM
                                    if (place.poi.car_park.occupied !== undefined) {
                                        let availability = calculavailabilityByThird(place.poi.car_park.available_PRM, place.poi.properties['capacity:disabled'])
                                        html += `<div class="places_container ${availability}_places">
                                                    <div class="icon">
                                                        <i class="ikisio ikisio-handi"></i>
                                                    </div>
                                                    <img class="realtime" src="${Kisio.realtime_data_picto_svg}" alt="Realtime picto" />
                                                    ${place.poi.car_park.available_PRM} ${Translator.trans('proximity.data.bss.available_places')}
                                                </div>`
                                    }
                                }
                            html += `</div>`
                        }
    html +=         `</div>`;
    
    if (Kisio.type_product != "standalone") {
        html += new ButtonDepartureArrival({
            lat: place.poi.coord.lat,
            lng: place.poi.coord.lon,
            address: header_address,
            launchSearch: true
        }).buildHtml();
    }

    html += `</div>`;

    return html;
}

const generateContentHtmlFreeFloating = (freeFloating, address = null, isSinglePoi = false) => {
    let html = "";

    let header_address = Math.round(freeFloating.coord.lon * 1000000) / 1000000 + ';' + Math.round(freeFloating.coord.lat * 1000000) / 1000000;
    
    let publicId = freeFloating.public_id == undefined ? "" : freeFloating.public_id ;

    html += `<div class="poi_container" data-poi="${freeFloating.id}" tabindex="0">
                <div class="poi_title_container">
                    <span class="poi_title">${freeFloating.provider_name} ${publicId}</span>
                </div>
                <div class="poi_content_container freefloating_content_container">    
                    <div class="poi_content_distance">À ${freeFloating.distance}m</div>`
                    if (address != null) {
                        html += `<div class="freefloating_info">${address.display_name}</div>`;
                    }

                    if (!isSinglePoi) {
                        html += `<div class="freefloating_info">
                                    ${Translator.trans(Kisio.proximityMarkerPoiType[freeFloating.poi_type]['trad_key'])}
                                </div>`;
                    }

                    if (freeFloating.hasOwnProperty("battery")) {
                    html += `<div class="freefloating_info">
                                <img src="${parameters.urlImages}modes/Groupe 120.svg" alt="battery" />
                                ${freeFloating.battery} kms
                            </div>`
                    }
    html +=     `</div>`;
    
    if (Kisio.type_product != "standalone") {
        html += new ButtonDepartureArrival({
            lat: freeFloating.coord.lat,
            lng: freeFloating.coord.lon,
            address: header_address,
            launchSearch: true
        }).buildHtml();
    }

    html += `</div>`;

    return html; 
}

export const eventToggleProximityContainer = () => {
    document.querySelectorAll('.proximity_category_container .proximity_category_title').forEach((element) => {
        element.addEventListener("click", () => {
            element.parentNode.classList.toggle("proximity_category_container_close");
        });
    });
};

// Fix size marker after zoom
export const eventHoverBoardToMap = () => {
    document.querySelectorAll('.proximity_poi_content .poi_container').forEach((element) => {
        element.addEventListener("mouseenter", () => {
            const foundMarker = getMarkerInClusterGroupByOptions(element.getAttribute('data-poi'));
            if (!foundMarker) {
                return;
            }

            Kisio.layerGroup.proximity.poiGroupLayer.removeLayer(foundMarker);
            
            foundMarker.setIcon(L.divIcon({
                iconSize: [47, 47],
                iconAnchor: [16, 35],
                html: activeIconHtml(foundMarker),
                className: 'active-poi-marker'
            }));

            Kisio.layerGroup.proximity.proximityLayers.addLayer(foundMarker);
            Kisio.layerGroup.proximity.proximityLayers.addTo(window.leaflet.map);

            createPOIPopUp(foundMarker);

            window.leaflet.map.fitBounds([foundMarker.getLatLng()], mapPopupConfig());

            element.addEventListener("mouseleave", () => {
                Kisio.layerGroup.proximity.proximityLayers.removeLayer(foundMarker);
                Kisio.layerGroup.proximity.proximityLayers.removeFrom(window.leaflet.map);
                foundMarker.setIcon(L.icon({
                    iconUrl: JKisio(foundMarker).data('icon-poi-url'),
                    shadowUrl: parameters.urlImages + 'pin-proximity.svg',
                    ...icon_default_config
                }));
                Kisio.layerGroup.proximity.poiGroupLayer.addLayer(foundMarker);
            });
        })
    })
}

const mapPopupConfig = () => {
    const desktopBreakPoint = 600;
    const boardWidth = (JKisio('#board').length > 0) ? JKisio('#board').outerWidth() + JKisio('#board').offset().left : 0;

    if (JKisio('body').outerWidth(true) < desktopBreakPoint) {
        return {
            paddingTopLeft: L.point(boardWidth - 250, -250),
            paddingBottomRight: L.point(0, 0),
            maxZoom: 14
        }
    }

    return {
        paddingTopLeft: L.point(boardWidth + 30, 32),
        paddingBottomRight: L.point(0, 32)
    }
};

function getMarkerInClusterGroupByOptions(id) {
    const allMarkers = Kisio.layerGroup.proximity.poiGroupLayer.getLayers();
    for (const marker of allMarkers) {
      if (marker.options.id === id) {
        return marker;
      };
    };
    return null;
};

const calculavailabilityByThird = (els, total) => {
    let availability = "";
    switch (true) {
        case els === 0 && total === 0:
            availability = "no";
            break;
        case els > 4:
            availability = "available";
            break;
        case els > 2:
            availability = "almost_available";
            break;
        default:
            availability = "unavailable";
            break;
    }

    return availability;
}

const displayOpeningHours = (hours) => {
    let html = '';
    
    try {
        let oh = new opening_hours(hours, {address: {country_code: "fr"}});        
        let state = oh.getState();
        let nextChange = oh.getNextChange();
        
        html += '<div class="poi_content_opening_hours">';
        html += `${state ? '<span class="open">'+
            Translator.trans('places_nearby.open')
            +'</span>' : '<span class="closed">'+
            Translator.trans('places_nearby.close')
            +'</span>'}`;
    
        if (nextChange !== undefined) {
            html += `${state ? ' - ' + Translator.trans('places_nearby.toClose')
                : ' - ' + Translator.trans('places_nearby.toOpen') }`;
    
            html += ` ${nextChange.getHours()}h${nextChange.getMinutes() == '0' ? '' : nextChange.getMinutes()}`;
        }
        html += '</div>';
    } catch (e) {
    }

    return html;
}
