import { toggleStopArea, toggleStopAreaConst } from ".";

export function toggleStopAreaBuilder ({} = {}) {
    this.container = document.createElement('div');
    this.container.setAttribute('id', toggleStopAreaConst.id);
    this.container.setAttribute('tabindex', '0');
    this.container.innerText = selectInnerText();
}

toggleStopAreaBuilder.prototype.click = function() {
    toggleStopArea();
    this.innerText = selectInnerText();
}

toggleStopAreaBuilder.prototype.onKeyDown = function (event) {
    if (['Enter', 'NumpadEnter'].includes(event.key)) {
        this.click();
    }
}

toggleStopAreaBuilder.prototype.buildHtml = function() {
    jKisio(document);
    jKisio(document)
        .off("click", "#toggleStopArea")
        .on("click", "#toggleStopArea", this.click);

    jKisio(document)
        .off("keydown", "#toggleStopArea")
        .on("keydown", "#toggleStopArea", this.onKeyDown);

    return this.container.outerHTML;
}

toggleStopAreaBuilder.prototype.build = function () {
    this.container.addEventListener("click", this.click);
    this.container.addEventListener("keydown", (event) => this.onKeyDown(event));

    return this.container;
}

toggleStopAreaBuilder.prototype.html = function () {
    return selectInnerText();
}

const selectInnerText = () => {
    let queryString = decodeURIComponent(window.location.search);
    let params = new URLSearchParams(queryString);

    let showStop = "true";

    if (params.has(toggleStopAreaConst.showStop)) {
        showStop = params.get(toggleStopAreaConst.showStop);
    }

    if (showStop == "true") {
        return Translator.trans('proximity.map.hideStopsOnMap');
    }

    return Translator.trans('proximity.map.showStopsOnMap');
    
}

export const deleteToggleStopArea = () => {
    document.querySelector(`#${toggleStopAreaConst.id}`).remove()
}